
import { Component, Mixins } from 'vue-property-decorator'
import api from '@/plugins/api'
import Table from '@/mixins/table'
import Permissions from '@/mixins/Permissions'
import { today } from '@/constants/today'
import AddEmailToProvider from '@/views/email_to_providers/AddEmailToProvider.vue'
import DownloadButton from '@/components/buttons/DownloadButton.vue'

const datatableHeader = [
  { value: 'id', text: 'Id', sortable: true },
  { value: 'application_name', text: 'Kompania', sortable: true },
  { value: 'car_plate', text: 'Targa', sortable: true },
  { value: 'phone_number', text: 'Nr.Tel Paisjes', sortable: true },
  { value: 'provider', text: 'Kompania Telefonike', sortable: true },
  { value: 'action', text: 'Veprimi', sortable: true },
]

@Component({ components: { DownloadButton, AddEmailToProvider } })
export default class EmailToProvidersList extends Mixins(Table, Permissions) {
  headers = datatableHeader
  today = today

  fetchData() {
    this.loading = true
    const query = this.prepareQuery({})
    api()
      .get(`api/providers/sent-mails${query}`)
      .then(({ data }) => {
        this.data = data.data
        this.totalRecords = data.meta.total
      })
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }
}
