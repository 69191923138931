
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class SubmitButton extends Vue {
  @Prop() label!: any
  @Prop() xLarge!: boolean
  @Prop({ default: false, type: Boolean }) block!: boolean
  @Prop() confirm!: boolean
  @Prop({ default: '' }) value!: string
  hasNotDescription = true
  dialog = false

  get syncValue(): string {
    return this.value
  }

  set syncValue(value: string) {
    this.$emit('input', value)
  }

  confirmBeforeSubmitting() {
    if (!this.confirm) {
      return this.$emit('clicked')
    }
    if (this.value && this.value.length > 0) this.hasNotDescription = false
    this.dialog = true
  }

  @Watch('dialog')
  onDialogChange() {
    if (!this.dialog) this.hasNotDescription = true
  }

  submit() {
    this.$emit('clicked')
    this.dialog = false
  }
}
