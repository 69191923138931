
import { Component, Prop } from 'vue-property-decorator'
import api from '@/plugins/api'
import Form from '@/mixins/form'
import { mixins } from 'vue-class-component'
import DropdownData from '@/services/DropdownData'
import jobStatuses from '@/constants/jobStatuses'
import DateTimePicker from '@/components/DateTimePicker'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { mapActions } from 'vuex'
import base64ToFile, { src64 } from '@/utils/base64ToFile'

@Component({
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
  components: { SubmitButton, CloseButton, EditButton, DeleteButton, DateTimePicker },
})
export default class EditOperation extends mixins(Form, DropdownData) {
  @Prop() id!: number | string
  @Prop() operationDone!: boolean
  dialog = false
  src64 = src64
  request: any = {}
  jobStatuses = jobStatuses
  isFileInputFocused = true

  focusFileInput() {
    this.isFileInputFocused = true
  }

  blurFileInput() {
    this.isFileInputFocused = false
  }

  get imagesAsFiles(): Array<File> {
    if (this.request.images)
      return this.request.images.map((image: string, index: number) => base64ToFile(image, index))
    return []
  }

  set imagesAsFiles(value: Array<File>) {
    if (!value.length) {
      this.request.images = []
      return
    }
    if (!this.isFileInputFocused) {
      value.map(image => {
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = () => {
          this.request.images.push((reader.result as string).split(',')[1])
        }
      })
    }
  }

  async save() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().put(`api/coordination${!this.operationDone ? '/todo' : ''}/${this.id}`, this.request)
      if (data) {
        this.close()
        this.$emit('operation-edited')
        this.$toast.success(data.message)
      }
    } catch (err) {
      if (err) {
        this.errors = err.response.data.errors || {}
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  fetchData() {
    this.fetchCities()
    this.fetchTechnicians()
    this.fetchDevices()
    this.fetchClients()
    this.fetchDevicesAssignableStatuses()
    this.fetchGroups()
    this.fetchCoordinationOperations()
    api()
      .get(`api/coordination/${this.id}`)
      .then(async ({ data }) => {
        this.request = data.data
        const images: { image: string }[] = data.data.images
        this.request.images = []
        this.$forceUpdate()
        this.request.images = images.map(({ image }) => image)
      })
  }

  async openImg(base64: string) {
    const image = new Image()
    image.src = this.src64(base64)
    const w = await window.open('')
    if (w) w.document.write(image.outerHTML)
  }

  removeImg(index: number) {
    this.request.images.splice(index, 1)
  }

  close() {
    this.dialog = false
    this.clearErrors()
  }
}
