
import { Component, Mixins } from 'vue-property-decorator'
import api from '@/plugins/api'
import Table from '@/mixins/table'
import ShowOperation from '@/views/coordination/ShowOperation.vue'
import Permissions from '@/mixins/Permissions'
import jobStatusColor from '@/helpers/jobStatusColor'
import ItCheckoutSearch from '@/views/it_checkout/partials/ItCheckoutSearch.vue'
import ItCheckBox from '@/views/it_checkout/ItCheckBox.vue'
import ItMarkLocation from '@/views/it_checkout/ItMarkLocation.vue'

const datatableHeader = [
  { value: 'group_name', text: 'Grupi/Makina', sortable: true },
  { value: 'client_name', text: 'Klienti', sortable: true },
  { value: 'new_plate', text: 'Targa Re', sortable: true },
  { value: 'old_plate', text: 'Targa Vjetër', sortable: true },
  { value: 'activity_name', text: 'Veprimi', sortable: true },
  { value: 'device_name', text: 'Paisja', sortable: true },
  { value: 'city_name', text: 'Qyteti', sortable: false },
  { value: 'created_at', text: 'Krijuar', sortable: true },
  { value: 'assigned_date_hour', text: 'Caktuar', sortable: true },
  { value: 'activity_date', text: 'Mbaruar', sortable: true },
  { value: 'actions', sortable: false },
]

@Component({ components: { ItMarkLocation, ItCheckBox, ItCheckoutSearch, ShowOperation } })
export default class ItCheckoutList extends Mixins(Table, Permissions) {
  headers = datatableHeader

  request = {
    coordination_activity_id: null,
    groups: [],
    client_name: null,
    activity_date: null,
    created_at: null,
    job_status: [],
    coordination_id: null,
    is_invoiced: null,
    city_id: null,
    plate_name: null,
    show_checked: false,
  }

  fetchData() {
    this.loading = true
    const query = this.prepareQuery({})
    api()
      .get(`api/it-checkout${query}`, { params: this.request })
      .then(({ data }) => {
        this.data = data.data
        this.totalRecords = data.meta.total
      })
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }

  color({ job_status }: { job_status: string }) {
    return jobStatusColor(job_status)
  }
}
