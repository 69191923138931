
import { Vue, Prop, Component, Model, Watch } from 'vue-property-decorator'
import api from '@/plugins/api'

type PlatePhone = Record<string, unknown> | null

@Component({})
export default class PlatePhonePerEmailSelect extends Vue {
  items = []
  loading = false
  @Prop() @Model('input') platePhone!: PlatePhone
  @Prop({ type: [Number, String], default: '' }) companyApplication!: number | string

  get syncPlatePhone(): PlatePhone {
    return this.platePhone
  }
  set syncPlatePhone(value) {
    this.$emit('input', value)
  }

  async mounted() {
    if (this.companyApplication) await this.fetchData()
  }

  @Watch('companyApplication')
  async fetchData() {
    if (!this.companyApplication) return this.setEmpty()
    this.loading = true
    try {
      const { data } = await api().get(
        `api/gps-gate/applications/${this.companyApplication}/car-details?no-pagination=true`
      )
      if (data) this.items = data.data
    } finally {
      if (this.syncPlatePhone) this.syncPlatePhone = null
      this.loading = false
    }
  }
  async setEmpty() {
    if (this.syncPlatePhone) this.syncPlatePhone = null
    this.items = []
  }
}
