import api from '@/plugins/api'
import { Module } from 'vuex'
import axios from 'axios'
type State = {
  accessToken: string | null
  refreshToken: string | null
  firstName: string | null
  secondName: string | null
  permissions: Array<{ id: number; name: string }>
  roles: Array<string>
}

const auth: Module<State, object> = {
  state: {
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    firstName: localStorage.getItem('firstName') || null,
    secondName: localStorage.getItem('secondName') || null,
    permissions: JSON.parse(localStorage.getItem('permissions') || '[]') || [],
    roles: JSON.parse(localStorage.getItem('roles') || '[]') || [],
  },
  mutations: {
    LOGIN(state, authenticationData) {
      state.accessToken = authenticationData.accessToken
      state.refreshToken = authenticationData.refreshToken
      state.firstName = authenticationData.firstName
      state.secondName = authenticationData.secondName
      state.permissions = authenticationData.permissions
      state.roles = authenticationData.roles
    },
    LOGOUT(state) {
      state.accessToken = null
      state.refreshToken = null
      state.firstName = null
      state.secondName = null
      state.roles = []
    },
    REFRESH_TOKEN(state, authenticationData) {
      state.accessToken = authenticationData.accessToken
      state.refreshToken = authenticationData.refreshToken
    },
  },
  actions: {
    sendLoginRequest: async ({ commit }, payload) => {
      const { data } = await api().post('api/login', payload)
      {
        if (data.data.access_token) {
          const authenticationData = {
            accessToken: data.data.access_token,
            refreshToken: data.data.refresh_token,
            firstName: data.data.user.name,
            secondName: data.data.user.surname,
            permissions: data.data.user.permissions,
            roles: data.data.user.roles,
          }
          localStorage.setItem('accessToken', authenticationData.accessToken)
          localStorage.setItem('refreshToken', authenticationData.refreshToken)
          localStorage.setItem('firstName', authenticationData.firstName)
          localStorage.setItem('secondName', authenticationData.secondName)
          localStorage.setItem('permissions', JSON.stringify(authenticationData.permissions))
          localStorage.setItem('roles', JSON.stringify(authenticationData.roles))
          await commit('LOGIN', authenticationData)
        }
      }
    },
    sendLogoutRequest: async context => {
      if (context.getters.loggedIn) await api().post('api/logout')
      localStorage.clear()
      context.commit('LOGOUT')
    },
    logout: async ({ commit }) => {
      localStorage.clear()
      commit('LOGOUT')
    },
    sendRefreshTokenRequest: async ({ commit }) => {
      const response = await axios.post(
        'api/refreshtoken',
        {},
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            RefreshToken: localStorage.getItem('refreshToken'),
          },
          baseURL: process.env.VUE_APP_BASE_URL,
        }
      )
      if (response.data.data.access_token) {
        const authenticationData = {
          accessToken: response.data.data.access_token,
          refreshToken: response.data.data.refresh_token,
        }
        localStorage.setItem('accessToken', authenticationData.accessToken)
        localStorage.setItem('refreshToken', authenticationData.refreshToken)
        commit('REFRESH_TOKEN', authenticationData)
      }
    },
  },
  getters: {
    loggedIn: ({ accessToken }) => accessToken !== null,
    fullName: ({ firstName, secondName }) => `${firstName} ${secondName}`,
    refreshToken: ({ refreshToken }) => refreshToken,
    accessToken: ({ accessToken }) => accessToken,
    permissions: ({ permissions }) => permissions,
    roles: ({ roles }) => roles,
  },
}
export default auth
