
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ components: {} })
export default class TableOfContentCard extends Vue {
  @Prop() table!: any
  routerPush(path: string) {
    if (this.$route.path !== path) this.$router.push(path)
  }
}
