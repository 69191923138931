import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { BootstrapVueIcons } from 'bootstrap-vue'
import './assets/css/app.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import VueToast from 'vue-toast-notification'
import './plugins/vueApexCharts'

Vue.use(BootstrapVueIcons)
Vue.use(VueToast, { position: 'top-right' })

Vue.config.productionTip = false
declare global {
  interface Window {
    axios: any
  }
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
