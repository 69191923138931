
import { Vue, Prop, Component, Model } from 'vue-property-decorator'
import api from '@/plugins/api'

type Provider = number | string | { name: string; id: string | number }[]

@Component({})
export default class ProviderSelect extends Vue {
  items = []
  loading = false
  @Prop() @Model('input') provider!: Provider

  get syncProvider(): Provider {
    return this.provider
  }
  set syncProvider(value) {
    this.$emit('input', value)
  }

  async mounted() {
    this.loading = true
    try {
      const { data } = await api().get('api/providers/settings?no-pagination=true')
      if (data) this.items = data.data
    } finally {
      this.loading = false
    }
  }
}
