
import { Component, Prop, Vue } from 'vue-property-decorator'
import ShowButton from '@/components/buttons/ShowButton.vue'
import CloseButton from '@/components/buttons/CloseButton.vue'

@Component({ components: { CloseButton, ShowButton } })
export default class ShowDialogView extends Vue {
  dialog = false
  @Prop({ default: true, type: Boolean }) showEyeIcon!: boolean
  @Prop() fullscreen!: boolean
  @Prop() title!: string
  @Prop() width!: string | number
}
