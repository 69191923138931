export const src64 = (base64: string) => 'data:image/;base64,' + base64

export default (base64: string, index = 0): File => {
  const [head, b64] = src64(base64).split(',')
  const [_, mime] = head.match(/:(.*?);/) || []
  const bstr = atob(b64)
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], `image${index + 1}.jpg`, { type: mime })
}
