
import { Component, Mixins } from 'vue-property-decorator'
import api from '@/plugins/api'
import Table from '@/mixins/table'
import AssignDevice from '@/views/warehouse/AssignDevice.vue'
import Permissions from '@/mixins/Permissions'
import DownloadButton from '@/components/buttons/DownloadButton.vue'
import { today } from '@/constants/today'
import WarehouseSearch from '@/views/warehouse/partials/WarehouseSearch.vue'

const datatableHeader = [
  { value: 'device_name', text: 'Paisja', sortable: true },
  { value: 'total_new', text: 'Të Reja', sortable: false },
  { value: 'total_used', text: 'Të Përdorura', sortable: false },
  { value: 'total_damaged', text: 'Të Dëmtuara', sortable: false },
  { value: 'total', text: 'Total', sortable: false },
]

@Component({ components: { WarehouseSearch, AssignDevice, DownloadButton } })
export default class Warehouse extends Mixins(Table, Permissions) {
  headers = datatableHeader
  today = today
  request = {
    device_name: null,
    amount: null,
    enable_zero: null,
    operator: null,
  }
  fetchData() {
    this.loading = true
    const query = this.prepareQuery({})
    api()
      .get(`api/warehouses${query}`, { params: this.request })
      .then(({ data }) => {
        this.data = data.data
        this.totalRecords = data.meta.total
      })
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }
}
