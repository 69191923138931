
import api from '@/plugins/api'
import Form from '@/mixins/form'
import { mapActions } from 'vuex'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import AddButton from '@/components/buttons/AddButton.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CloseButton from '@/components/buttons/CloseButton.vue'
import DeviceSelect from '@/components/selectors/DeviceSelect.vue'
import ShowButton from '@/components/buttons/ShowButton.vue'
import PricePerDeviceTable, { DevicePrice } from '@/views/clients/components/PricePerDeviceTable.vue'

type Payload = { name: string; device_prices: Array<DevicePrice> }

@Component({
  components: { ShowButton, PricePerDeviceTable, DeviceSelect, EditButton, SubmitButton, CloseButton, AddButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class ClientForm extends Mixins(Form) {
  dialog = false
  @Prop({ default: 'create', type: String }) viewType!: string
  @Prop({ default: '', type: [String, Number] }) id!: string
  request: Payload = { name: '', device_prices: [] }

  get readonly() {
    return this.viewType === 'show'
  }
  async fetch() {
    const { data } = await api().get(`/api/companies/${this.id}`)
    this.request = data.data
  }

  get isFinanceOrAdminRole() {
    return this.$store.getters.roles.includes('finance') || this.$store.getters.roles.includes('admin')
  }

  async create() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().post('api/companies', this.request)
      if (data) {
        this.$emit('created')
        this.$toast.success(data.message)
        this.close()
      }
    } catch (err) {
      if (err) {
        this.errors = err.response.data.errors || {}
        this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }
  async edit() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().patch(`/api/companies/${this.id}`, this.request)
      if (data) this.$toast.success(data.message)
      this.$emit('edited')
      this.close()
    } catch (err) {
      this.errors = err.response.data.errors || {}
      this.$toast.error(err.response.data.message)
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  close() {
    this.dialog = false
    this.request.name = ''
    this.request.device_prices = []
    this.clearErrors()
  }
}
