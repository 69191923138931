
import { Component, Prop } from 'vue-property-decorator'
import api from '@/plugins/api'
import { mixins } from 'vue-class-component'
import Form from '@/mixins/form'
import { mapActions } from 'vuex'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

@Component({
  components: { SubmitButton, CloseButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class ChangeUserPassword extends mixins(Form) {
  dialog = false
  @Prop() id!: number
  request: any = { new_password: null, new_password_confirmation: null }

  async saveChanges() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().put(`api/update-password/${this.id}`, this.request)
      if (data) {
        this.$emit('password-changed')
        this.close()
        this.$toast.success(data.message)
      }
    } catch (err) {
      if (err) {
        this.errors = err.response.data.errors || {}
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  close() {
    this.dialog = false
    this.request.password = null
    this.request.password_confirmation = null
    this.clearErrors()
  }
}
