
import { Component, Mixins, Prop } from 'vue-property-decorator'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import api from '@/plugins/api'
import { mapActions } from 'vuex'
import Form from '@/mixins/form'
import DropdownData from '@/services/DropdownData'
import toBase64 from '@/utils/toBase64'
import WarningButton from '@/components/buttons/WarningButton.vue'
import TerrainOperation from '@/views/terrain_coordination/mixins/TerrainOperation'
import DescriptionDetailsItem from '@/components/details_items/DescriptionDetailsItem.vue'
import moment from 'moment'

@Component({
  components: { DescriptionDetailsItem, WarningButton, SubmitButton, CloseButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class TerrainDeviceOperation extends Mixins(Form, DropdownData, TerrainOperation) {
  @Prop() item!: any
  dialog = false

  async submit(status = 'failed') {
    this.showOverlay()
    this.request.job_status = status
    const images: Array<string> = []
    for (const image of this.request.images as Array<File>) images.push(await toBase64(image))

    try {
      const { data } = await api().post(`api/coordination-technicians/${this.item.coordination_id}?_method=PUT`, {
        ...this.request,
        images,
      })
      if (data) {
        await this.$emit('operation-edited')
        await this.$toast.success(data.message)
        await this.close()
      }
    } catch (err) {
      if (err.response) {
        this.errors = err.response.data.errors || {}
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      await this.hideOverlay()
    }
  }

  formattedDate(date: string) {
    return moment(date).format('DD/mm/YYYY hh:mm:ss')
  }

  copyDescription() {
    navigator.clipboard.writeText(this.request.description_coordination)
  }

  openMap() {
    open(`https://www.google.com/maps/search/?api=1&query=${this.request.latitude},${this.request.longitude}`)
  }

  fetchData() {
    this.request.technician_id = this.item.technician_id
    this.request.coordination_activity_id = this.item.coordination_activity_id
    this.fetchDevicesPerGroup(this.item.group_id, true)
    this.fetchCoordinationOperations()
    this.fetchDevices()
    this.fetchDevicesAssignableStatuses()
    api()
      .get(`api/coordination-technicians/${this.item.coordination_id}`)
      .then(({ data }) => (this.request = data.data))
  }
}
