
import { Component, Prop, Vue } from 'vue-property-decorator'
import api from '@/plugins/api'
import ShowButton from '@/components/buttons/ShowButton.vue'
import DetailsItem from '@/components/details_items/DetailsItem.vue'
import CloseButton from '@/components/buttons/CloseButton.vue'

@Component({ components: { CloseButton, DetailsItem, ShowButton } })
export default class ShowUser extends Vue {
  dialog = false
  @Prop() id!: number | string
  user: any = {}

  fetchData() {
    api()
      .get(`api/users/${this.id}`)
      .then(({ data }) => (this.user = data.data))
  }
}
