import { Component, Vue } from 'vue-property-decorator'
import hasPermission from '@/router/permissions/hasPermission'

@Component
export default class MenuItems extends Vue {
  get menuItems() {
    return [
      {
        title: 'Magazinimi',
        children: [
          {
            title: 'Magazina',
            link: '/warehouse',
            permission: hasPermission('show_warehouse'),
          },
          {
            title: 'Gjendja Grupit',
            link: '/move-stock-to-control',
            permission: hasPermission('show_move_to_control'),
          },
          {
            title: 'Gjendja në Kontrollim',
            link: '/move-stock-to-warehouse',
            permission: hasPermission('show_move_to_warehouse'),
          },
          {
            title: 'Furnizim i Ri',
            link: '/new-warehouse-stock',
            permission: hasPermission('add_warehouse'),
          },
        ],
        permission: hasPermission('warehouse_module'),
      },
      {
        title: 'Kordinimi',
        link: '/coordination',
        permission: hasPermission('show_coordination'),
      },
      {
        title: 'Kordinimi-IT',
        link: '/it-checkout',
        permission: hasPermission('show_it_checkout'),
      },
      {
        title: 'Administrimi',
        children: [
          {
            title: 'E-mail to Providers',
            link: '/email-to-providers',
            permission: hasPermission('show_email_to_providers'),
            children: [
              {
                title: 'Email Settings',
                link: '/email-to-provider-settings',
                permission: hasPermission('show_email_to_providers_settings'),
              },
              {
                title: 'Email to Providers Template',
                link: '/email-to-providers-template',
                permission: hasPermission('show_email_to_providers_template'),
              },
            ],
          },
          {
            title: 'Veprimet',
            link: '/operations',
            permission: hasPermission('show_actions'),
          },
          {
            title: 'Historiku i Inventarit',
            link: '/group-records',
            permission: hasPermission('show_group_records'),
          },
          {
            title: 'Teknikët',
            link: '/technicians',
            permission: hasPermission('show_technicians'),
          },
          {
            title: 'Grupet',
            link: '/groups',
            permission: hasPermission('show_groups'),
          },
          {
            title: 'Klientët',
            link: '/clients',
            permission: hasPermission('show_clients'),
          },
          {
            title: 'Paisjet',
            link: '/devices',
            permission: hasPermission('show_devices'),
          },
          {
            title: 'Statuset',
            link: '/statuses',
            permission: hasPermission('show_statuses'),
          },
          {
            title: 'Menaxhimi i Përdoruesve',
            children: [
              {
                title: 'Historiku',
                link: '/history',
                permission: hasPermission('show_history'),
              },
              {
                title: 'Përdoruesit',
                link: '/users',
                permission: hasPermission('show_users'),
              },
              {
                title: 'Rolet',
                link: '/roles',
                permission: hasPermission('show_roles'),
              },
            ],
            permission: hasPermission('show_history') || hasPermission('show_users') || hasPermission('show_roles'),
          },
        ],
        permission: hasPermission('administration_module'),
      },
    ]
  }

  get tableOfContent() {
    return [
      {
        titleColor: 'pink',
        title: 'Magazinimi',
        permission: hasPermission('warehouse_module'),
        items: [
          {
            title: 'Magazina',
            path: '/warehouse',
            icon: 'mdi-warehouse',
            permission: hasPermission('show_warehouse'),
          },
          {
            title: 'Gjendja Grupit',
            path: '/move-stock-to-control',
            icon: 'mdi-clipboard-plus',
            permission: hasPermission('show_move_to_control'),
          },
          {
            title: 'Gjendja në Kontrollim',
            path: '/move-stock-to-warehouse',
            icon: 'mdi-source-branch-check',
            permission: hasPermission('show_move_to_warehouse'),
          },
          {
            title: 'Furnizim i Ri',
            path: '/new-warehouse-stock',
            icon: 'mdi-inbox-arrow-down',
            permission: hasPermission('add_warehouse'),
          },
        ],
      },
      {
        titleColor: 'yellow',
        title: 'Kordinimi',
        permission: hasPermission('coordination_module'),
        items: [
          {
            title: 'Kordinimi',
            path: '/coordination',
            icon: 'mdi-account-network',
            permission: hasPermission('show_coordination'),
          },
          {
            title: 'Kordinimi-IT',
            path: '/it-checkout',
            icon: 'mdi-check-network',
            permission: hasPermission('show_it_checkout'),
          },
        ],
      },
    ]
  }
}
