
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import AddButton from '@/components/buttons/AddButton.vue'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import api from '@/plugins/api'
import { mapActions } from 'vuex'
import Form from '@/mixins/form'
import DropdownData from '@/services/DropdownData'
import coordinationJobStatuses from '@/constants/coordinationJobStatuses'
import { today } from '@/constants/today'
import DateTimePicker from '@/components/DateTimePicker'
import moment from 'moment'

@Component({
  components: { DateTimePicker, SubmitButton, CloseButton, AddButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class CreateOperation extends Mixins(Form, DropdownData) {
  @Prop() fullscreen!: boolean
  jobStatuses = coordinationJobStatuses
  dismissedDialog = true
  dialog = false
  request = {
    group_id: null,
    coordination_activity_id: null,
    client_name: null,
    phone_number: null,
    description_coordination: null,
    latitude: null,
    longitude: null,
    activity_date: '',
    created_at: '',
    updated_at: '',
    old_device_id: null,
    description_terrain: null,
    job_status: null,
    priority: null,
    city_id: null,
    assigned_date_hour: '',
  }

  fetchData() {
    this.fetchGroups()
    this.fetchClients()
    this.fetchCoordinationOperations()
    this.fetchCities()
    this.request.activity_date = today
  }

  async assignOperation() {
    if (!this.request.assigned_date_hour) this.request.assigned_date_hour = moment().format('YYYY-mm-DD hh:mm:ss')
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().post('api/coordination', this.request)
      if (data) {
        this.$emit('item-dispatched')
        this.$toast.success(data.message)
        this.close(false)
      }
    } catch (err) {
      if (err) {
        this.errors = err.response.data.errors || {}
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  close(isDismissed: boolean) {
    if (!isDismissed) this.dismissedDialog = false
    this.dialog = false
    this.request = {
      group_id: null,
      coordination_activity_id: null,
      client_name: null,
      phone_number: null,
      description_coordination: null,
      assigned_date_hour: '',
      latitude: null,
      longitude: null,
      activity_date: '',
      old_device_id: null,
      description_terrain: null,
      job_status: null,
      created_at: '',
      updated_at: '',
      priority: null,
      city_id: null,
    }
    this.clearErrors()
  }

  @Watch('dialog')
  onDialogChange() {
    if (!this.dialog && this.dismissedDialog) this.$emit('assignation-dismissed')
    this.dismissedDialog = true
  }
}
