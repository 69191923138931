
import { Component, Mixins, Prop } from 'vue-property-decorator'
import SearchButton from '@/components/buttons/SearchButton.vue'
import DatePicker from '@/components/DatePicker.vue'
import DropdownData from '@/services/DropdownData'
import ClearSearchButton from '@/components/buttons/ClearSearchButton.vue'
import { itJobStatuses } from '@/constants/jobStatuses'
import DownloadButton from '@/components/buttons/DownloadButton.vue'
import { today } from '@/constants/today'
import Permissions from '@/mixins/Permissions'

@Component({ components: { DownloadButton, ClearSearchButton, DatePicker, SearchButton } })
export default class CoordinationSearch extends Mixins(DropdownData, Permissions) {
  @Prop() value!: any
  @Prop({ default: false, type: Boolean }) disabledSearch!: boolean
  @Prop({ default: true, type: Boolean }) showExtraSearchParams!: boolean
  jobStatuses = itJobStatuses
  today = today

  beforeMount() {
    this.fetchGroups()
    this.fetchCities()
    this.fetchClients()
    this.fetchCoordinationOperations()
  }

  clearSearch() {
    this.value.coordination_activity_id = null
    this.value.groups = []
    this.value.client_name = null
    this.value.city_id = null
    this.value.activity_date = null
    this.value.created_at = null
    this.value.job_status = null
    this.value.plate_name = null
    this.value.show_checked = false
  }
}
