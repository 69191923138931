import { Component, Vue, Watch } from 'vue-property-decorator'
import convert from 'image-file-resize'
const toMbSize = (size: number) => +(Math.round(+size / 1024) / 1000).toFixed(2)
@Component({})
export default class TerrainOperation extends Vue {
  [x: string]: any
  images: any = []
  request: any = { coordination_activity_id: '', images: [] }

  async addPhoto() {
    if (!('images' in this.request)) this.request.images = []
    await this.$forceUpdate()
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'image/*'
    input.multiple = true
    input.click()
    input.addEventListener('change', () => {
      if (input.files) this.images = [...this.images, ...input.files]
    })
    this.clearSpecificErrors('images')
  }

  @Watch('images', { deep: true })
  async onImagesChange() {
    if (this.images) {
      this.request.images = []
      for (const file of this.images) {
        const fileReader = new FileReader()
        const image = new Image()

        await fileReader.readAsDataURL(file)

        fileReader.onload = async () => {
          const fileSizeMb = toMbSize(file.size)
          image.src = fileReader.result as string
          await this.$forceUpdate()
          if (fileSizeMb > 10) {
            let { width, height } = image
            const aspectRatio = width / height
            width = 1024 * aspectRatio
            height = 1024 / aspectRatio
            try {
              const img = await convert({ file, width, height, type: file.type })
              if (img) this.request.images.push(img)
            } catch (err) {
              this.$toast.error(`${err}  ${file.type}`)
              this.images = []
              this.request.images = []
            }
          } else {
            this.request.images.push(image)
          }
        }
      }
      return
    }
    this.request.images = []
  }

  close() {
    this.images = []
    this.request = {}
    this.dialog = false
    this.clearErrors()
  }

  newPlateToUpperCase() {
    if (this.request.new_plate) this.request.new_plate = (this.request.new_plate as string).toUpperCase()
  }

  oldPlateToUpperCase() {
    if (this.request.old_plate) this.request.old_plate = (this.request.old_plate as string).toUpperCase()
  }

  get isMountActivity() {
    return this.request.coordination_activity_id === 1
  }

  get isDismountActivity() {
    return this.request.coordination_activity_id === 2
  }

  get isTransferActivity() {
    return this.request.coordination_activity_id === 3
  }

  get isReplacementActivity() {
    return this.request.coordination_activity_id === 4
  }

  get isNotBaseActivity() {
    return ![1, 2, 3, 4].some(value => value === this.request.coordination_activity_id)
  }

  get isClientTransferActivity() {
    return this.request.coordination_activity_id === 12
  }

  get isClientDeviceOperation() {
    return [7, 8, 12].some(value => value === this.request.coordination_activity_id)
  }
}
