
import { Component, Mixins } from 'vue-property-decorator'
import api from '@/plugins/api'
import Table from '@/mixins/table'
import AddDevice from '@/views/devices/AddDevice.vue'
import EditDevice from '@/views/devices/EditDevice.vue'
import Permissions from '@/mixins/Permissions'

const datatableHeader = [
  { value: 'id', text: 'Id', sortable: true },
  { value: 'name', text: 'Emri', sortable: true },
  { value: 'actions', text: 'Veprime', sortable: false },
]

@Component({
  components: { EditDevice, AddDevice },
})
export default class DevicesList extends Mixins(Table, Permissions) {
  headers = datatableHeader

  fetchData() {
    this.loading = true
    const query = this.prepareQuery({})
    api()
      .get(`api/devices${query}`)
      .then(({ data }) => {
        this.data = data.data
        this.totalRecords = data.meta.total
      })
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }
}
