
import { Component } from 'vue-property-decorator'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import AssignButton from '@/components/buttons/AssignButton.vue'
import { mapActions } from 'vuex'
import { mixins } from 'vue-class-component'
import Form from '@/mixins/form'
import DropdownData from '@/services/DropdownData'
import GroupRecordsSearch from '@/views/group_records/partials/GroupRecordsSearch.vue'
import api from '@/plugins/api'
import GroupRecordsGraph from '@/views/group_records/GroupRecordsGraph.vue'
import ShowAuditTrail from '@/views/audit_trail/ShowAuditTrail.vue'

@Component({
  components: {
    ShowAuditTrail,
    GroupRecordsGraph,
    GroupRecordsSearch,
    SubmitButton,
    CloseButton,
    AssignButton,
  },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class GroupRecords extends mixins(Form, DropdownData) {
  request = {
    group_id: null,
    device_id: null,
    status_id: null,
    from_date: null,
    to_date: null,
  }
  data = {
    activity_date: null,
    amount: null,
    transferred_amount: null,
    id: null,
  }
  clickedMarkerId: any = null
  fetchData() {
    api()
      .get('api/group-records', { params: this.request })
      .then(({ data }) => (this.data = data.data))
      .catch(({ response }) => this.$toast.error(response.data.message))
  }
  get disableSearch() {
    return (
      this.request.group_id === null ||
      this.request.device_id === null ||
      this.request.status_id === null ||
      this.request.from_date === null ||
      this.request.to_date === null
    )
  }
  showGraph() {
    return (
      this.data.amount !== null &&
      this.data.activity_date !== null &&
      this.data.transferred_amount !== null &&
      this.data.id !== null
    )
  }
}
