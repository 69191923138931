
import { Component, Mixins } from 'vue-property-decorator'
import Form from '@/mixins/form'
import api from '@/plugins/api'
import { mapActions } from 'vuex'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import DropdownData from '@/services/DropdownData'
import AddDevice from '@/views/devices/AddDevice.vue'
import Permissions from '@/mixins/Permissions'

@Component({
  components: { AddDevice, SubmitButton, CloseButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class NewWarehouseStock extends Mixins(Form, DropdownData, Permissions) {
  request = { device_id: null, status_id: null, amount: null }

  beforeMount() {
    this.fetchDevices()
    this.fetchDevicesStatuses()
  }

  async addStock() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().post('api/warehouses/new', this.request)
      if (data) {
        this.routerPush()
        this.$toast.success(data.message)
      }
    } catch (err) {
      if (err) {
        this.errors = err.response.data.errors || {}
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  routerPush() {
    if (this.$route.path !== '/warehouse') this.$router.push('/warehouse')
  }
}
