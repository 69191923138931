
import { Component, Prop } from 'vue-property-decorator'
import { mapActions } from 'vuex'
import { mixins } from 'vue-class-component'
import Permissions from '@/mixins/Permissions'
import api from '@/plugins/api'

@Component({ methods: { ...mapActions(['hideOverlay', 'showOverlay']) } })
export default class CloseClient extends mixins(Permissions) {
  @Prop() id!: any
  dialog = false

  async closeClient() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().delete(`api/clients/${this.id}`)
      if (data) {
        this.$toast.success(data.message)
        this.$emit('client-closed')
      }
    } catch (err) {
      if (err) await this.$toast.error(err.response.data.message)
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }
}
