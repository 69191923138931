
import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Permissions from '@/mixins/Permissions'
import LocationMarker from '@/components/LocationMarker.vue'
import CreateOperation from '@/views/coordination/CreateOperation.vue'
import ShowOperation from '@/views/coordination/ShowOperation.vue'
import { AxiosResponse } from 'axios'
import api from '@/plugins/api'

@Component({
  components: {
    ShowOperation,
    CreateOperation,
    LocationMarker,
  },
})
export default class MobileCoordinationOperations extends mixins(Permissions) {
  locations = []
  clickedMarkerId: any = null

  beforeMount() {
    this.fetchData()
  }

  fetchData() {
    api()
      .get('api/coordination-locations?no-pagination=true')
      .then(({ data }) => (this.locations = data.data))
  }

  openCreateDialog({ lat, lng }: any) {
    if (this.hasPermission('add_coordination')) {
      ;(this.$refs.coordinationDialog as Vue).$data.dialog = true
      ;(this.$refs.coordinationDialog as Vue).$data.request.latitude = lat
      ;(this.$refs.coordinationDialog as Vue).$data.request.longitude = lng
      ;(this.$refs.coordinationDialog as Vue & {
        fetchData: () => Promise<AxiosResponse>
      }).fetchData()
    } else {
      this.locations.splice(-1)
      alert('You have no permission !')
    }
  }

  async showMarkerOperationDialog(id: number) {
    this.clickedMarkerId = id
    await this.$forceUpdate()
    ;((this.$refs.markerShowDialog as Vue).$refs.showDialog as Vue).$data.dialog = true
    await (this.$refs.markerShowDialog as Vue & {
      fetchData: () => Promise<AxiosResponse>
    }).fetchData()
    this.clickedMarkerId = null
  }
}
