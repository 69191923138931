
import { Component, Prop, Mixins } from 'vue-property-decorator'
import api from '@/plugins/api'
import Form from '@/mixins/form'
import AddButton from '@/components/buttons/AddButton.vue'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { mapActions } from 'vuex'
import CompanyApplicationSelect from '@/components/selectors/CompanyApplicationSelect.vue'
import PlatePhonePerEmailSelect from '@/components/selectors/PlatePhonePerEmailSelect.vue'
import ProviderSelect from '@/components/selectors/ProviderSelect.vue'
type PlatePhone = { phone: string; plate: string }
type Application = { name: string; id?: number }
@Component({
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
  components: {
    ProviderSelect,
    PlatePhonePerEmailSelect,
    CompanyApplicationSelect,
    AddButton,
    SubmitButton,
    CloseButton,
  },
})
export default class AddEmailToProvider extends Mixins(Form) {
  @Prop({ default: '', type: [String, Number] }) id!: string
  emailActions: Array<{ name: string; id: string; end_point: string }> = []
  application: Application | null = null
  dialog = false
  loading = false
  request = {
    application_name: '',
    phone_number: '',
    car_plate: '',
    provider_id: '',
  }

  async fetchActions() {
    this.loading = true
    try {
      const { data } = await api().get(
        `api/providers/actions-to-trigger/${this.request.provider_id}?no-pagination=true`
      )
      if (data) this.emailActions = data.data
    } finally {
      this.loading = false
    }
  }

  copyPlatePhone() {
    navigator.clipboard.writeText(`${this.request.car_plate} ${this.request.phone_number}`)
  }

  async create(action_id: string) {
    if (!action_id) return this.$toast.error('Ups, Dicka nuk punon!')
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().post(`api/providers/send-mail`, { ...this.request, action_id })
      if (data) {
        await this.close()
        this.$emit('created')
        this.$toast.success(data.message)
      }
    } catch (err) {
      this.errors = err.response.data.errors || {}
      this.$toast.error(err.response.data.message)
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  handlePlatePhoneInput(value: PlatePhone) {
    this.request.car_plate = value.plate
    this.request.phone_number = value.phone
    this.clearFieldError('car_plate')
    this.clearFieldError('phone_number')
  }

  get platePhoneErrors(): Array<string> {
    return ([] as Array<string>).concat(
      this.errors.car_plate ? this.errors.car_plate : [],
      this.errors.phone_number ? this.errors.phone_number : []
    )
  }

  get syncApplication() {
    return this.application as Application
  }

  set syncApplication({ id, name }: Application) {
    this.application = { id, name }
    this.request.application_name = name
  }
  close() {
    this.dialog = false
    this.syncApplication = { name: '', id: undefined }
    this.request.application_name = ''
    this.request.car_plate = ''
    this.request.phone_number = ''
    this.request.provider_id = ''
    this.clearErrors()
  }
}
