
import { Component, Prop } from 'vue-property-decorator'
import Form from '@/mixins/form'
import { mixins } from 'vue-class-component'
import AddDialogView from '@/views/core/dialog_view/AddDialogView.vue'
import RemoveTagButton from '@/components/buttons/RemoveTagButton.vue'
import AddButton from '@/components/buttons/AddButton.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import api from '@/plugins/api'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { mapActions } from 'vuex'
import ShowButton from '@/components/buttons/ShowButton.vue'

@Component({
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
  components: { ShowButton, SubmitButton, CloseButton, EditButton, AddButton, RemoveTagButton, AddDialogView },
})
export default class AddProviders extends mixins(Form) {
  dialog = false
  loading = false
  @Prop({ default: 'create', type: String }) viewType!: string
  @Prop({ default: '', type: [String, Number] }) id!: string
  request = { name: '', receiver_email: '', cc: [] as Array<string> }
  ccErrors: Array<number> = []

  get readonly() {
    return this.viewType === 'show'
  }
  ccFormatter(emails: Array<{ id: string; email: string }>) {
    return emails ? emails.map(({ email }) => email) : []
  }

  removeTag(index: number) {
    if (index > -1) this.request.cc.splice(index, 1)
    this.ccErrors = []
  }

  async fetchItem() {
    this.loading = true
    try {
      const { data } = await api().get(`/api/providers/settings/${this.id}`)
      if (data) {
        this.request = data.data
        this.request.cc = this.ccFormatter(data.data.cc)
      }
    } catch (err) {
      if (err) {
        this.close()
        this.$toast.error('Ups... Forma nuk mund të hapej!')
      }
    } finally {
      this.loading = false
    }
  }

  async create() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().post('/api/providers/settings', this.request)
      if (data) {
        await this.close()
        this.$emit('created')
        this.$toast.success(data.message)
      }
    } catch (err) {
      this.errors = err.response.data.errors || {}
      this.ccErrors = this.tagFieldErrors(err.response.data.errors || {}, 'cc.')
      this.$toast.error(err.response.data.message)
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }
  async edit() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().patch(`/api/providers/settings/${this.id}`, this.request)
      if (data) {
        await this.close()
        this.$emit('edited')
        this.$toast.success(data.message)
      }
    } catch (err) {
      this.errors = err.response.data.errors || {}
      this.ccErrors = this.tagFieldErrors(err.response.data.errors || {}, 'cc.')
      this.$toast.error(err.response.data.message)
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  close() {
    this.dialog = false
    this.request.name = ''
    this.request.receiver_email = ''
    this.request.cc = []
    this.ccErrors = []
    this.clearErrors()
  }
}
