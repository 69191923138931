import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import Ripple from 'vuetify/lib/directives/ripple'

Vue.use(Vuetify, { directives: { Ripple } })

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#82B1FF',
        secondary: '#424242',
        accent: '#EE44AA',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        'light-grey': '#F0F0F0',
      },
    },
  },
  icons: { iconfont: 'mdi' },
})
