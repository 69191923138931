
import { Component, Mixins, Prop } from 'vue-property-decorator'
import SearchButton from '@/components/buttons/SearchButton.vue'
import DatePicker from '@/components/DatePicker.vue'
import ClearSearchButton from '@/components/buttons/ClearSearchButton.vue'
import DropdownData from '@/services/DropdownData'

@Component({
  components: { ClearSearchButton, DatePicker, SearchButton },
})
export default class AuditTrailSearch extends Mixins(DropdownData) {
  @Prop() value!: any

  beforeMount() {
    this.fetchGroups()
  }

  search() {
    this.$emit('search')
  }

  clearSearch() {
    this.value.user_name = null
    this.value.coordination_activity_name = null
    this.value.group_id = null
    this.value.activity_date = null
  }
}
