
import { Component, Mixins } from 'vue-property-decorator'
import Table from '@/mixins/table'
import api from '@/plugins/api'
import Permissions from '@/mixins/Permissions'
const datatableHeader = [
  { value: 'group_name', text: 'Paisja', sortable: false },
  { value: 'total_new', text: 'Statusi', sortable: false },
  { value: 'total_new', text: 'Sasia', sortable: false },
]
@Component({})
export default class DevicesPerTechnician extends Mixins(Table, Permissions) {
  headers = datatableHeader
  beforeMount() {
    this.fetchData()
  }

  fetchData() {
    this.loading = true
    api()
      .get('api/coordination-technicians/inventory/group')
      .then(({ data }) => (this.data = data.data))
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }
}
