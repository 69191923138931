
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class WarningButton extends Vue {
  @Prop() label!: any
  @Prop() xLarge!: boolean
  @Prop() confirm!: boolean
  @Prop() noButtonMargins!: boolean
  dialog = false

  confirmBeforeSubmitting() {
    if (!this.confirm) {
      return this.$emit('clicked')
    }
    this.dialog = true
  }
  submit() {
    this.$emit('clicked')
    this.dialog = false
  }
}
