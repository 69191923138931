
import { Component, Prop } from 'vue-property-decorator'
import api from '@/plugins/api'
import Form from '@/mixins/form'
import { mixins } from 'vue-class-component'
import EditDialogView from '@/views/core/dialog_view/EditDialogView.vue'

@Component({ components: { EditDialogView } })
export default class EditTechnician extends mixins(Form) {
  @Prop() id!: number | string
  request = {}

  fetchData() {
    api()
      .get(`api/technicians/${this.id}`)
      .then(({ data }) => (this.request = data.data))
  }
}
