
import { Vue, Component, Prop } from 'vue-property-decorator'
import api from '@/plugins/api'
import downloadDocument from '@/utils/downloadDocument'
import { mapActions } from 'vuex'

@Component({ methods: { ...mapActions(['hideOverlay', 'showOverlay']) } })
export default class DownloadButton extends Vue {
  @Prop({ type: String, default: '' }) label!: string
  @Prop({ required: true }) api!: string
  @Prop({ required: true }) fileName!: string
  @Prop() searchParams!: any
  @Prop() disabled!: boolean

  downloadFile() {
    //@ts-ignore
    this.showOverlay()
    api()
      .get(`${this.api}`, { responseType: 'blob', params: this.searchParams })
      .then(async ({ data }) => {
        if (data.type === 'application/json') this.$toast.success(JSON.parse(await data.text()).message)
        if (data.type !== 'application/json') downloadDocument(`${this.fileName}`, data)
      })
      .catch(async ({ response }) => {
        this.$toast.error(JSON.parse(await response.data.text()).message)
      }) //@ts-ignore
      .finally(this.hideOverlay())
  }
}
