
import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import hasPermission from '@/router/permissions/hasPermission'

@Component({ methods: { ...mapActions(['disableMap', 'enableMap']) }, computed: { ...mapGetters(['isMapEnabled']) } })
export default class CoordinationMapSwitch extends Vue {
  hasPermission = hasPermission
  get syncIsMapEnabled() {
    //@ts-ignore
    return this.isMapEnabled
  }
  set syncIsMapEnabled(_) {
    //@ts-ignore
    this.isMapEnabled ? this.disableMap() : this.enableMap()
  }
}
