import Vue from 'vue'
import VueRouter, { RouteConfig, RouteRecord } from 'vue-router'
import store from '@/store'
import ChangeAuthPassword from '@/views/ChangeAuthPassword.vue'
import Login from '@/modules/login/Login.vue'
import Dashboard from '@/modules/main_menu/Dashboard.vue'
import Warehouse from '@/views/warehouse/Warehouse.vue'
import NewWarehouseStock from '@/views/new_warehouse_stock/NewWarehouseStock.vue'
import TechniciansList from '@/views/technicians/TechniciansList.vue'
import GroupsList from '@/views/groups/GroupsList.vue'
import MoveStockToWarehouse from '@/views/move_stock_to_warehouse/MoveStockToWarehouse.vue'
import MoveStockToControl from '@/views/move_stock_to_control/MoveStockToControl.vue'
import Coordination from '@/views/coordination/Coordination.vue'
import DevicesList from '@/views/devices/DevicesList.vue'
import StatusesList from '@/views/statuses/StatusesList.vue'
import OperationsList from '@/views/operations/OperationsList.vue'
import AuditTrail from '@/views/audit_trail/AuditTrail.vue'
import UsersList from '@/views/users/UsersList.vue'
import Roles from '@/views/roles/Roles.vue'
import hasPermission, { allowed as permission } from '@/router/permissions/hasPermission'
import isMobile from '@/router/permissions/isMobile'
import MobileDashboard from '@/modules/main_menu/MobileDashboard.vue'
import MobileCoordinationOperations from '@/views/coordination/mobile/MobileCoordinationOperations.vue'
import MobileOperationsList from '@/views/coordination/mobile/MobileOperationsList.vue'
import TerrainCoordinationList from '@/views/terrain_coordination/TerrainCoordinationList.vue'
import OpenClientsList from '@/views/terrain_coordination/OpenClientsList.vue'
import DevicesPerTechnician from '@/views/terrain_coordination/DevicesPerTechnician.vue'
import RecentlyClosedJobs from '@/views/terrain_coordination/RecentlyClosedJobs.vue'
import JobsPerPlate from '@/views/terrain_coordination/JobsPerPlate.vue'
import ClientsList from '@/views/clients/ClientsList.vue'
import GroupRecords from '@/views/group_records/GroupRecords.vue'
import ItCheckoutList from '@/views/it_checkout/ItCheckoutList.vue'
import SystemConfigs from '@/views/SystemConfigs.vue'
import EmailToProviderSettingsList from '@/views/email_to_providers_settings/EmailToProviderSettingsList.vue'
import EmailToProvidersList from '@/views/email_to_providers/EmailToProvidersList.vue'
import EmailToProvidersTemplateList from '@/views/email_to_providers_template/EmailToProvidersTemplateList.vue'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { isAuth: true },
  },
  {
    path: '/mobile-dashboard',
    name: 'MobileDashboard',
    component: MobileDashboard,
    meta: { permission: 'mobile_dashboard', requiresAuth: true },
    children: [
      {
        path: '/mobile-change-password',
        name: 'MobileChangeAuthPassword',
        component: ChangeAuthPassword,
        meta: { permission: 'mobile_dashboard', requiresAuth: true },
      },
      {
        path: '/assign-coordination-operation',
        name: 'MobileCoordinationOperations',
        component: MobileCoordinationOperations,
        meta: { permission: 'show_coordination', requiresAuth: true },
      },
      {
        path: '/coordination-operations-list',
        name: 'MobileOperationsList',
        component: MobileOperationsList,
        meta: { permission: 'show_coordination', requiresAuth: true },
      },
      {
        path: '/terrain-coordination-operations-list',
        name: 'TerrainCoordinationList',
        component: TerrainCoordinationList,
        meta: { permission: 'show_technician_coordination', requiresAuth: true },
      },
      {
        path: '/terrain-open-clients-list',
        name: 'OpenClientsList',
        component: OpenClientsList,
        meta: { permission: 'show_technician_coordination', requiresAuth: true },
      },
      {
        path: '/my-devices',
        name: 'DevicesPerTechnician',
        component: DevicesPerTechnician,
        meta: { permission: 'show_technician_coordination', requiresAuth: true },
      },
      {
        path: '/recently-closed',
        name: 'RecentlyClosedJobs',
        component: RecentlyClosedJobs,
        meta: { permission: 'show_technician_coordination', requiresAuth: true },
      },
      {
        path: '/jobs-per-plate',
        name: 'JobsPerPlate',
        component: JobsPerPlate,
        meta: { permission: 'show_coordination_technician', requiresAuth: true },
      },
    ],
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { permission: 'dashboard', requiresAuth: true },
    children: [
      {
        path: '/change-password',
        name: 'ChangeAuthPassword',
        component: ChangeAuthPassword,
        meta: { permission: 'dashboard', requiresAuth: true },
      },
      {
        path: '/warehouse',
        name: 'Warehouse',
        component: Warehouse,
        meta: { permission: 'show_warehouse', requiresAuth: true },
      },
      {
        path: '/new-warehouse-stock',
        name: 'NewWarehouseStock',
        component: NewWarehouseStock,
        meta: { permission: 'add_warehouse', requiresAuth: true },
      },
      {
        path: '/move-stock-to-warehouse',
        name: 'MoveStockToWarehouse',
        component: MoveStockToWarehouse,
        meta: { permission: 'show_move_to_warehouse', requiresAuth: true },
      },
      {
        path: '/move-stock-to-control',
        name: 'MoveStockToControl',
        component: MoveStockToControl,
        meta: { permission: 'show_move_to_control', requiresAuth: true },
      },
      {
        path: '/technicians',
        name: 'TechniciansList',
        component: TechniciansList,
        meta: { permission: 'show_technicians', requiresAuth: true },
      },
      {
        path: '/groups',
        name: 'GroupsList',
        component: GroupsList,
        meta: { permission: 'show_groups', requiresAuth: true },
      },
      {
        path: '/devices',
        name: 'DevicesList',
        component: DevicesList,
        meta: { permission: 'show_devices', requiresAuth: true },
      },
      {
        path: '/statuses',
        name: 'StatusesList',
        component: StatusesList,
        meta: { permission: 'show_statuses', requiresAuth: true },
      },
      {
        path: '/operations',
        name: 'OperationsList',
        component: OperationsList,
        meta: { permission: 'show_actions', requiresAuth: true },
      },
      {
        path: '/coordination',
        name: 'Coordination',
        component: Coordination,
        meta: { permission: 'show_coordination', requiresAuth: true },
      },
      {
        path: '/group-records',
        name: 'GroupRecords',
        component: GroupRecords,
        meta: { permission: 'show_group_records', requiresAuth: true },
      },
      {
        path: '/history',
        name: 'AuditTrail',
        component: AuditTrail,
        meta: { permission: 'show_history', requiresAuth: true },
      },
      {
        path: '/users',
        name: 'UsersList',
        component: UsersList,
        meta: { permission: 'show_users', requiresAuth: true },
      },
      {
        path: '/roles',
        name: 'Roles',
        component: Roles,
        meta: { permission: 'show_roles', requiresAuth: true },
      },
      {
        path: '/clients',
        name: 'ClientsList',
        component: ClientsList,
        meta: { permission: 'show_clients', requiresAuth: true },
      },
      {
        path: '/it-checkout',
        name: 'ItCheckoutList',
        component: ItCheckoutList,
        meta: { permission: 'show_it_checkout', requiresAuth: true },
      },
      {
        path: '/configs',
        name: 'SystemConfigs',
        component: SystemConfigs,
        meta: { permission, requiresAuth: true },
      },
      {
        path: '/email-to-providers',
        name: 'EmailToProvidersList',
        component: EmailToProvidersList,
        meta: { permission: 'show_email_to_providers', requiresAuth: true },
      },
      {
        path: '/email-to-providers-template',
        name: 'EmailToProvidersTemplateList',
        component: EmailToProvidersTemplateList,
        meta: { permission: 'show_email_to_providers_template', requiresAuth: true },
      },
      {
        path: '/email-to-provider-settings',
        name: 'EmailToProviderSettingsList',
        component: EmailToProviderSettingsList,
        meta: { permission: 'show_email_to_providers_settings', requiresAuth: true },
      },
    ],
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(({ meta }: RouteRecord) => meta.requiresAuth)) {
    if (!store.getters.loggedIn) next({ name: 'Login' })
    //@ts-ignore
    else if (!hasPermission(to.meta.permission) && hasPermission('dashboard') && !isMobile())
      next({ name: 'Dashboard' })
    //@ts-ignore
    else if (!hasPermission(to.meta.permission)) next({ name: 'MobileDashboard' })
    else next()
  } else next()

  to.matched.some(({ meta }: RouteRecord) => meta.isAuth)
    ? store.getters.loggedIn && hasPermission('dashboard')
      ? next({ name: 'Dashboard' })
      : store.getters.loggedIn && hasPermission('mobile_dashboard')
      ? next({ name: 'MobileDashboard' })
      : next()
    : next()
})
export default router
