
import { Component, Mixins, Prop } from 'vue-property-decorator'
import SearchButton from '@/components/buttons/SearchButton.vue'
import DatePicker from '@/components/DatePicker.vue'
import ClearSearchButton from '@/components/buttons/ClearSearchButton.vue'
import DropdownData from '@/services/DropdownData'
import Form from '@/mixins/form'

@Component({
  components: { ClearSearchButton, DatePicker, SearchButton },
})
export default class AuditTrailSearch extends Mixins(DropdownData, Form) {
  @Prop() value!: any
  @Prop() disabled!: boolean

  beforeMount() {
    this.fetchGroups()
    this.fetchDevices()
    this.fetchDevicesStatuses()
  }

  search() {
    this.$emit('search')
  }

  clearSearch() {
    this.value.group_id = null
    this.value.device_id = null
    this.value.status_id = null
    this.value.from_date = null
    this.value.to_date = null
  }
}
