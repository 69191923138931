
import VueApexCharts from 'vue-apexcharts'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ShowAuditTrail from '@/views/audit_trail/ShowAuditTrail.vue'
import { AxiosResponse } from 'axios'

@Component({
  components: { ShowAuditTrail, apexcharts: VueApexCharts },
})
export default class GroupRecordsGraph extends Vue {
  @Prop() chartData!: any
  showGraph = true
  historyId = -0
  amountData: any[] = []
  dateData: any[] = []
  transferredData: any[] = []
  showAlert = false

  async getChartData() {
    this.chartData.map((key: any) => this.dateData.push(key.activity_date))
    this.chartData.map((key: any) => this.amountData.push(parseInt(key.amount)))
    this.chartData.map((key: any) => this.transferredData.push(key.transferred_amount))
  }

  @Watch('chartData', { deep: true })
  async onChartDataChange() {
    this.showGraph = false
    this.amountData.length = 0
    this.dateData.length = 0
    this.transferredData.length = 0
    await this.getChartData()
    await this.$forceUpdate()
    this.showGraph = true
    this.showAlert = false
  }

  beforeMount() {
    this.getChartData()
  }

  chartOptions = {
    chart: { id: 'historiku', zoom: { enabled: false } },
    xaxis: { categories: this.dateData },
  }
  series = [
    { name: 'Sasia', data: this.amountData },
    { name: 'Sasia e transferuar', data: this.transferredData },
  ]

  async markerClick(event: any, chartContext: any, { dataPointIndex }: any) {
    this.historyId = this.chartData[dataPointIndex].id
    await this.$forceUpdate()
    ;(this.$refs.showDialog as Vue).$data.dialog = true
    await (this.$refs.showDialog as Vue & {
      fetchData: () => Promise<AxiosResponse>
    }).fetchData()
  }

  hasData() {
    if (this.chartData.length !== 0) return true
    this.showAlert = true
  }
}
