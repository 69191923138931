
import { Component } from 'vue-property-decorator'
import Form from '@/mixins/form'
import { mixins } from 'vue-class-component'
import AddDialogView from '@/views/core/dialog_view/AddDialogView.vue'

@Component({ components: { AddDialogView } })
export default class AddDevice extends mixins(Form) {
  request = { name: null }

  close() {
    this.request.name = null
    this.clearErrors()
  }
}
