
import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Permissions from '@/mixins/Permissions'
import CloseClient from '@/views/terrain_coordination/partials/CloseClient.vue'

@Component({ components: { CloseClient } })
export default class ClientActions extends mixins(Permissions) {
  @Prop() item!: any
}
