
import { Component, Prop, Watch } from 'vue-property-decorator'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import AssignButton from '@/components/buttons/AssignButton.vue'
import { mapActions } from 'vuex'
import { mixins } from 'vue-class-component'
import Form from '@/mixins/form'
import DropdownData from '@/services/DropdownData'
import api from '@/plugins/api'

@Component({
  components: { SubmitButton, CloseButton, AssignButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class DeviceTransfer extends mixins(Form, DropdownData) {
  dialog = false
  @Prop() groupData!: any[]
  request: any = {
    warehouse_id: null,
    to_group: null,
    from_group: null,
    amount: null,
  }
  devicesPerGroupConcatenated: any[] = []

  async assign() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().put('api/transfer-devices', this.request)
      if (data) {
        this.$toast.success(data.message)
        this.$emit('device-transferred')
        this.close()
      }
    } catch (err) {
      if (err) {
        this.errors = err.response.data.errors || {}
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  @Watch('request.from_group')
  async onFromGroupChange() {
    await this.fetchDevicesPerGroup(this.request.from_group, false)
    this.devicesPerGroupConcatenated = this.devicesPerGroup.map((device: any) => ({
      name: `${device.device_name}     -      ${device.status_name}`,
      ...device,
    }))
  }

  close() {
    this.dialog = false
    this.request = {
      to_group: null,
      from_group: null,
      amount: null,
      warehouse_id: null,
    }
    this.clearErrors()
  }
}
