
import { Component, Mixins, Prop } from 'vue-property-decorator'
import SearchButton from '@/components/buttons/SearchButton.vue'
import DatePicker from '@/components/DatePicker.vue'
import ClearSearchButton from '@/components/buttons/ClearSearchButton.vue'
import DropdownData from '@/services/DropdownData'
import AssignDevice from '@/views/warehouse/AssignDevice.vue'
import CloseButton from '@/components/buttons/CloseButton.vue'

@Component({
  components: {
    CloseButton,
    ClearSearchButton,
    DatePicker,
    SearchButton,
    AssignDevice,
  },
})
export default class WarehouseSearch extends Mixins(DropdownData) {
  @Prop() value!: any

  beforeMount() {
    this.fetchGroups()
  }

  search() {
    this.$emit('search')
  }

  clearSearch() {
    this.value.device_name = null
    this.value.amount = null
    this.value.enable_zero = null
    this.value.operator = false
  }
}
