
import { Component, Prop } from 'vue-property-decorator'
import Form from '@/mixins/form'
import { mixins } from 'vue-class-component'
import EditDialogView from '@/views/core/dialog_view/EditDialogView.vue'

@Component({ components: { EditDialogView } })
export default class EditOperation extends mixins(Form) {
  @Prop() id!: number | string
  @Prop() operationName!: string
  request: { name: string | null } = { name: null }

  fetchProps() {
    this.request.name = this.operationName
  }
}
