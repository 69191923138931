
import { Component, Mixins, Prop, Model, Watch } from 'vue-property-decorator'
import Permissions from '@/mixins/Permissions'
import api from '@/plugins/api'
import { mapActions } from 'vuex'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

@Component({
  components: { SubmitButton, CloseButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class InvoiceCheckbox extends Mixins(Permissions) {
  @Prop() @Model('change') value!: number | boolean
  @Prop({ default: NaN, type: Number, required: true }) id!: number
  @Prop({ default: '', type: String }) notes!: string
  dialog = false
  dismissedDialog = true
  request = { notes: '' }

  get syncValue(): number | boolean {
    if (typeof this.value === 'number') {
      if (this.value === 0) return false
      if (this.value === 1) return true
    }
    return this.value
  }
  set syncValue(value) {
    this.$emit('change', value)
  }

  async changeFinanceStatus() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().put(`api/coordination/${this.id}/is-invoiced`, this.request)
      this.$toast.success(data.message)
      this.$emit('update-notes', this.request.notes)
      this.close(false)
    } catch (err) {
      if (err) this.$toast.error(err.response.data.message)
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  close(isDismissed: boolean) {
    if (!isDismissed) this.dismissedDialog = false
    this.dialog = false
  }

  @Watch('dialog')
  onDialogChange() {
    if (!this.dialog && this.dismissedDialog) this.syncValue = !this.syncValue
    if (this.dialog) this.request.notes = this.notes
    this.dismissedDialog = true
  }
}
