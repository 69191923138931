import { Module } from 'vuex'
export const configs: Module<{ enabledMap: boolean | null; enabledFullSearchCoordination: boolean | null }, object> = {
  state: {
    enabledMap: JSON.parse(localStorage.getItem('enabledMap') as string),
    enabledFullSearchCoordination: JSON.parse(localStorage.getItem('enabledFullSearchCoordination') as string),
  },
  mutations: {
    ENABLE_MAP(state) {
      state.enabledMap = true
    },
    DISABLE_MAP(state) {
      state.enabledMap = false
    },
    ENABLE_FULL_SEARCH_COORDINATION(state) {
      state.enabledFullSearchCoordination = true
    },
    DISABLE_FULL_SEARCH_COORDINATION(state) {
      state.enabledFullSearchCoordination = false
    },
  },
  actions: {
    enableMap({ getters, commit }) {
      localStorage.setItem('enabledMap', 'true')
      if (!getters.isMapEnabled) commit('ENABLE_MAP')
    },
    disableMap({ getters, commit }) {
      localStorage.setItem('enabledMap', 'false')
      if (getters.isMapEnabled) commit('DISABLE_MAP')
    },
    enableFullSearchCoordination({ getters, commit }) {
      localStorage.setItem('enabledFullSearchCoordination', 'true')
      if (!getters.isFullSearchCoordinationEnabled) commit('ENABLE_FULL_SEARCH_COORDINATION')
    },
    disableFullSearchCoordination({ getters, commit }) {
      localStorage.setItem('enabledFullSearchCoordination', 'false')
      if (getters.isFullSearchCoordinationEnabled) commit('DISABLE_FULL_SEARCH_COORDINATION')
    },
  },
  getters: {
    isMapEnabled: ({ enabledMap }) => enabledMap,
    isFullSearchCoordinationEnabled: ({ enabledFullSearchCoordination }) => enabledFullSearchCoordination,
  },
}

export default configs
