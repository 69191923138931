
import { Component, Mixins, Prop } from 'vue-property-decorator'
import api from '@/plugins/api'
import Form from '@/mixins/form'
import { mapActions } from 'vuex'
import EditButton from '@/components/buttons/EditButton.vue'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import AddButton from '@/components/buttons/AddButton.vue'
import ProviderSelect from '@/components/selectors/ProviderSelect.vue'
import { VueEditor } from 'vue2-editor'
import ShowButton from '@/components/buttons/ShowButton.vue'
@Component({
  components: { ShowButton, ProviderSelect, AddButton, SubmitButton, CloseButton, EditButton, VueEditor },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class EmailToProvidersTemplateForm extends Mixins(Form) {
  dialog = false
  loading = false
  @Prop({ default: 'create', type: String }) viewType!: string
  @Prop({ default: '', type: [String, Number] }) id!: string
  request = { name: '', provider_id: '', subject: '', body: '' }
  get readonly() {
    return this.viewType === 'show'
  }

  async fetchItem() {
    this.loading = true
    try {
      const { data } = await api().get(`/api/providers/actions/${this.id}`)
      if (data) this.request = data.data
    } catch (err) {
      if (err) {
        this.close()
        this.$toast.error('Ups... Forma nuk mund të hapej!')
      }
    } finally {
      this.loading = false
    }
  }

  async edit() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().patch(`/api/providers/actions/${this.id}`, this.request)
      if (data) {
        this.$emit('edited')
        this.close()
        this.$toast.success(data.message)
      }
    } catch (err) {
      this.errors = err.response.data.errors || {}
      this.$toast.error(err.response.data.message)
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  async create() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().post('/api/providers/actions', this.request)
      if (data) {
        this.$emit('created')
        this.close()
        this.$toast.success(data.message)
      }
    } catch (err) {
      this.errors = err.response.data.errors || {}
      this.$toast.error(err.response.data.message)
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  close() {
    this.dialog = false
    this.request.name = ''
    this.request.provider_id = ''
    this.request.subject = ''
    this.request.body = ''
    this.clearErrors()
  }
}
