
import { Component, Mixins, Prop } from 'vue-property-decorator'
import SearchButton from '@/components/buttons/SearchButton.vue'
import DatePicker from '@/components/DatePicker.vue'
import DropdownData from '@/services/DropdownData'
import ClearSearchButton from '@/components/buttons/ClearSearchButton.vue'
import jobStatuses from '@/constants/jobStatuses'
import DownloadButton from '@/components/buttons/DownloadButton.vue'
import { today } from '@/constants/today'
import Permissions from '@/mixins/Permissions'
import AddButton from '@/components/buttons/AddButton.vue'
import { mapGetters } from 'vuex'
import CoordinationSearchSwitch from '@/components/CoordinationSearchSwitch.vue'
import CoordinationMapSwitch from '@/components/CoordinationMapSwitch.vue'

@Component({
  components: {
    CoordinationMapSwitch,
    CoordinationSearchSwitch,
    AddButton,
    DownloadButton,
    ClearSearchButton,
    DatePicker,
    SearchButton,
  },
  computed: { ...mapGetters(['isMapEnabled', 'isFullSearchCoordinationEnabled']) },
})
export default class CoordinationSearch extends Mixins(DropdownData, Permissions) {
  @Prop() value!: any
  @Prop({ default: false, type: Boolean }) disabledSearch!: boolean
  jobStatuses = jobStatuses
  today = today
  get isAdmin() {
    return this.$store.getters.roles.includes('admin')
  }

  beforeMount() {
    this.fetchGroups()
    this.fetchCities()
    this.fetchClients()
    this.fetchCoordinationOperations()
  }

  clearSearch() {
    this.value.coordination_activity_id = null
    this.value.groups = []
    this.value.client_name = null
    this.value.city_id = null
    this.value.activity_date_from = null
    this.value.activity_date_end = null
    this.value.created_at_from = null
    this.value.created_at_end = null
    this.value.job_status = []
    this.value.plate_name = null
    this.value.invoiced = null
  }
}
