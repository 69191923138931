
import { Component, Mixins, Watch } from 'vue-property-decorator'
import api from '@/plugins/api'
import ClientActions from '@/views/terrain_coordination/ClientActions.vue'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import DropdownData from '@/services/DropdownData'
import Form from '@/mixins/form'
import toBase64 from '@/utils/toBase64'
import { mapActions } from 'vuex'
import TerrainOperation from '@/views/terrain_coordination/mixins/TerrainOperation'

@Component({
  components: { SubmitButton, CloseButton, ClientActions },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class OpenClientsList extends Mixins(Form, DropdownData, TerrainOperation) {
  loading = false
  data: any = []
  selectedDevices: any[] = []
  dialog = false
  devicesWithErrors: any[] = []
  beforeMount() {
    this.fetchClients()
  }

  fetchClients(noEmit = false) {
    if (!noEmit) this.$emit('fetching')
    this.loading = true
    api()
      .get(`api/clients`)
      .then(({ data }) => (this.data = data.data))
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }

  async submit() {
    //@ts-ignore
    this.showOverlay()
    this.request.job_status = 'done'
    await this.$forceUpdate()

    const images: Array<string> = []
    for (const image of this.request.images as Array<File>) images.push(await toBase64(image))

    if (!this.isMountActivity && !this.isDismountActivity && !this.isReplacementActivity) {
      try {
        const { data } = await api().post(`api/coordination-technicians`, { ...this.request, images })
        if (data) {
          this.close()
          this.fetchClients()
          this.$toast.success(data.message)
        }
      } catch (err) {
        if (err.response) {
          this.errors = err.response.data.errors || {}
          this.$toast.error(err.response.data.message)
        }
      } finally {
        this.hideOverlay()
      }
      return
    }
    this.request.old_device_id = null
    this.request.status_id = null
    await this.$forceUpdate()

    await this.showOverlay()
    for (const device of this.selectedDevices) {
      this.request.device_id = device[this.isDismountActivity ? 'id' : 'device_id']

      if (this.isReplacementActivity) this.request.old_device_id = device.old_device_id

      if (!this.isTransferActivity && !this.isDismountActivity) this.request.status_id = device.status_id

      await this.$forceUpdate()

      try {
        const { data } = await api().post(`api/coordination-technicians`, { ...this.request, images })
        if (data) await this.$toast.success(data.message)
      } catch (err) {
        device.error = true
        this.devicesWithErrors.push(device)
        this.$toast.error(err.response.data.message)
        if (err.response) {
          if (err.response.data.errors) this.errors = err.response.data.errors
        }
      }
    }

    if (this.hasDevicesWithErrors) {
      this.selectedDevices = [...this.devicesWithErrors]
    } else {
      this.selectedDevices = []
      this.close()
    }

    this.devicesWithErrors = []
    await this.fetchClients()
    await this.hideOverlay()
  }

  copyDescription() {
    navigator.clipboard.writeText(this.request.description_coordination)
  }

  @Watch('request.coordination_activity_id')
  onCoordinationActivityChange() {
    this.selectedDevices = []
    this.fetchDevices()
    if (this.request.group_id) this.fetchDevicesPerGroup(this.request.group_id, true)
  }

  get hasSelectedDevices() {
    return this.selectedDevices.length > 0
  }

  get hasDevicesWithErrors() {
    return this.devicesWithErrors.length > 0
  }

  async fetchData(jobDataFromClient: any) {
    this.dialog = true
    this.request = { ...jobDataFromClient }
    if (!('images' in this.request)) this.request.images = []
    this.fetchDevices()
    this.fetchDevicesAssignableStatuses()
    this.fetchCoordinationOperations()
    await this.$forceUpdate()
    await this.fetchDevicesPerGroup(this.request.group_id, true)
  }
}
