
import { Component } from 'vue-property-decorator'
import { mapActions } from 'vuex'
import { mixins } from 'vue-class-component'
import Form from '@/mixins/form'
import LoginButton from '@/components/buttons/LoginButton.vue'
import Permissions from '@/mixins/Permissions'
import isMobile from '@/router/permissions/isMobile'

@Component({ components: { LoginButton }, methods: { ...mapActions(['sendLoginRequest']) } })
export default class Login extends mixins(Form, Permissions) {
  loading = false
  request = { name: null, password: null }

  login() {
    this.loading = true
    //@ts-ignore
    this.sendLoginRequest(this.request)
      .then(() => {
        this.hasPermission('dashboard') && !isMobile()
          ? this.$router.push({ name: 'Dashboard' })
          : this.$router.push({ name: 'MobileDashboard' })
      })
      .catch(({ response }: any) => {
        if (response.status === 422) this.errors = response.data.errors
        this.$toast.error(response.data.message)
      })
      .finally(() => (this.loading = false))
  }
}
