import { Module } from 'vuex'

const loader: Module<{ loadingStatus: boolean }, object> = {
  state: { loadingStatus: false },
  mutations: {
    HIDE_LOADER(state) {
      state.loadingStatus = false
    },
    SHOW_LOADER(state) {
      state.loadingStatus = true
    },
  },
  actions: {
    showOverlay({ getters, commit }) {
      if (!getters.isLoading) commit('SHOW_LOADER')
    },
    hideOverlay({ getters, commit }) {
      if (getters.isLoading) commit('HIDE_LOADER')
    },
  },
  getters: {
    isLoading: ({ loadingStatus }) => loadingStatus,
  },
}
export default loader
