
import { Vue, Component, Mixins } from 'vue-property-decorator'
import Table from '@/mixins/table'
import api from '@/plugins/api'
import TerrainDeviceOperation from '@/views/terrain_coordination/TerrainDeviceOperation.vue'
import Permissions from '@/mixins/Permissions'
import OpenClientsList from '@/views/terrain_coordination/OpenClientsList.vue'
import { AxiosResponse } from 'axios'

@Component({ components: { OpenClientsList, TerrainDeviceOperation } })
export default class TerrainCoordinationList extends Mixins(Table, Permissions) {
  async fetchData(withClients = false) {
    this.loading = true
    api()
      .get(`api/coordination-technicians`)
      .then(({ data }) => (this.data = data.data))
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))

    if (this.$refs.openClientsList && withClients) {
      await (this.$refs.openClientsList as Vue & {
        fetchClients: (withEmit: boolean) => Promise<AxiosResponse>
      }).fetchClients(withClients)
    }
  }
}
