
import { Vue, Component, Prop, Model, Watch } from 'vue-property-decorator'
import PartialTimePicker from '@/components/DateTimePicker/PartialTimePicker.vue'

@Component({ components: { PartialTimePicker } })
export default class DateTimePicker extends Vue {
  @Prop({ type: [Date, String], default: null }) @Model('input') datetime!: string
  @Prop({ type: Object }) datePickerProps!: Record<string, unknown>
  @Prop({ type: Object }) timePickerProps!: Record<string, unknown>
  display = false
  activeTab = 0
  date = ''
  time = ''

  get selectedDatetime(): string {
    return this.date + ' ' + (!this.time ? '00:00:00' : this.time + ':00')
  }

  @Watch('date')
  @Watch('time')
  upDateValue() {
    this.$emit('input', this.selectedDatetime)
  }

  clear() {
    this.resetPicker()
    this.$emit('input', '')
  }

  resetPicker() {
    this.display = false
    this.activeTab = 0
    if (this.$refs.timer) {
      ;(this.$refs.timer as Vue & { selectingHour: boolean }).selectingHour = true
    }
  }

  showTimePicker() {
    this.activeTab = 1
  }

  showDatePicker() {
    this.activeTab = 0
  }
}
