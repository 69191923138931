
import { Component, Mixins } from 'vue-property-decorator'
import UserDropdown from '@/modules/main_menu/UserDropdown.vue'
import Permissions from '@/mixins/Permissions'
import api from '@/plugins/api'

@Component({ components: { UserDropdown } })
export default class MobileDashboard extends Mixins(Permissions) {
  private buttons: Array<{ link: string; text: string; color: string }> = []

  routerPush(path: string) {
    if (this.$route.path !== path) this.$router.push(path)
    location.reload()
  }

  beforeMount() {
    api().get('api/cities')
    addEventListener('visibilitychange', () => {
      if (!document.hidden) api().get('api/cities')
    })
    if (this.hasPermission('technician_module')) this.buttons = [...this.buttons, ...this.terrainButtons]
    if (this.hasPermission('coordination_module')) this.buttons = [...this.buttons, ...this.coordinationButtons]
  }

  terrainButtons = [
    {
      link: '/terrain-coordination-operations-list',
      text: 'Për tu Bërë',
      color: 'green lighten-1',
      fab: true,
      icon: 'mdi-face-agent',
    },
    {
      link: '/jobs-per-plate',
      text: 'Kontroll Targe',
      color: 'purple lighten-2',
      fab: true,
      icon: 'mdi-car',
    },
    {
      link: '/recently-closed',
      text: 'Bërë Sot',
      color: 'cyan lighten-1',
      fab: true,
      icon: 'mdi-text-box-check',
    },
    {
      link: '/my-devices',
      text: 'Magazina',
      color: 'lime darken-1',
      fab: true,
      icon: 'mdi-warehouse',
    },
  ]
  coordinationButtons = [
    {
      link: '/coordination-operations-list',
      text: 'Punet',
      color: 'green',
    },
    {
      link: '/assign-coordination-operation',
      text: 'Cakto Pune',
      color: 'teal',
    },
  ]
}
