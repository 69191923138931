import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import api from '@/plugins/api'
import { AxiosResponse } from 'axios'
@Component
export default class DropdownData extends Vue {
  groups: Array<AxiosResponse> = []
  devices: Array<AxiosResponse> = []
  devicesPerGroup: Array<AxiosResponse> = []
  devicesStatus: Array<AxiosResponse> = []
  technicians: Array<AxiosResponse> = []
  coordinationOperations: Array<AxiosResponse> = []
  devicesAssignableStatus: Array<AxiosResponse> = []
  roles: Array<AxiosResponse> = []
  clients: Array<AxiosResponse> = []
  cities: Array<AxiosResponse> = []

  fetchCoordinationOperations() {
    api()
      .get('api/coordination-activities?no-pagination=true')
      .then(({ data }) => (this.coordinationOperations = data.data))
  }

  fetchRoles() {
    api()
      .get('api/roles-all?no-pagination=true')
      .then(({ data }) => (this.roles = data.data))
  }

  fetchTechnicians() {
    api()
      .get('api/technicians?no-pagination=true')
      .then(({ data }) => (this.technicians = data.data))
  }

  fetchGroups() {
    api()
      .get('api/groups?no-pagination=true')
      .then(({ data }) => (this.groups = data.data))
  }

  fetchClients() {
    api()
      .get('api/companies?no-pagination=true')
      .then(({ data }) => (this.clients = data.data))
  }

  fetchDevices() {
    api()
      .get('api/devices?no-pagination=true')
      .then(({ data }) => (this.devices = data.data))
  }

  async fetchDevicesPerGroup(groupId: any, notForceReturnable?: boolean) {
    await api()
      .get(`api/group-inventories/${groupId}${notForceReturnable ? '?is_force_returnable=false' : ''}`)
      .then(async ({ data }) => (this.devicesPerGroup = data.data))
  }

  async fetchDevicesStatuses(filter?: any) {
    await api()
      .get('api/statuses?no-pagination=true')
      .then(({ data }) => (this.devicesStatus = data.data))
    if (filter) {
      const temp = [...this.devicesStatus]
      this.devicesStatus = temp.filter(({ id }: any) => {
        return filter.includes(id)
      })
    }
  }

  fetchDevicesAssignableStatuses() {
    api()
      .get('api/statuses/assign/new?no-pagination=true')
      .then(({ data }) => (this.devicesAssignableStatus = data.data))
  }

  fetchCities() {
    api()
      .get('api/cities?no-pagination=true')
      .then(({ data }) => (this.cities = data.data))
  }
}
