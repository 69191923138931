
import { Component, Prop, Vue } from 'vue-property-decorator'
import ShowButton from '@/components/buttons/ShowButton.vue'
import DetailsItem from '@/components/details_items/DetailsItem.vue'
import CloseButton from '@/components/buttons/CloseButton.vue'
import api from '@/plugins/api'
import DescriptionDetailsItem from '@/components/details_items/DescriptionDetailsItem.vue'

@Component({
  components: { DescriptionDetailsItem, CloseButton, DetailsItem, ShowButton },
})
export default class ShowAuditTrail extends Vue {
  dialog = false
  @Prop({ default: true }) showButton!: boolean
  @Prop() id!: string | number
  auditTrail = {}

  fetchData() {
    api()
      .get(`api/activities/${this.id}`)
      .then(({ data }) => (this.auditTrail = data.data))
  }
  close() {
    this.dialog = false
  }
}
