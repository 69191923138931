export enum JOB_STATUSES {
  failed = 'failed',
  todo = 'todo',
  done = 'done',
  ongoing = 'ongoing',
}
export const itJobStatuses = [
  { name: 'Bërë', id: JOB_STATUSES.done },
  { name: 'Duke u Bërë', id: JOB_STATUSES.ongoing },
]
export default [
  ...itJobStatuses,
  { name: 'Për tu Bërë', id: JOB_STATUSES.todo },
  { name: 'Dështuar', id: JOB_STATUSES.failed },
]
