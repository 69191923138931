
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'MenuNode' })
export default class MenuNode extends Vue {
  @Prop({ type: Object, required: true })
  node!: any
  child!: any

  routerPush(routePath: string) {
    if (this.$route.path !== routePath) this.$router.push(routePath)
  }
}
