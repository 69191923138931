
import { Component, Prop, Vue } from 'vue-property-decorator'
import AddButton from '@/components/buttons/AddButton.vue'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import api from '@/plugins/api'
import { mapActions } from 'vuex'

@Component({
  components: { SubmitButton, CloseButton, AddButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class DialogView extends Vue {
  @Prop() title!: string
  @Prop() icon!: string
  @Prop() width!: string | number
  @Prop() dark!: boolean
  @Prop() small!: boolean
  @Prop() label!: string
  @Prop() color!: string
  @Prop() submitButtonLabel!: string
  @Prop() closeButtonLabel!: string
  @Prop() api!: string
  @Prop() btnStyle!: string
  @Prop() request!: any
  @Prop() requestType!: string
  @Prop() fullscreen!: boolean
  dialog = false

  async dispatchRequest() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await (api as any)()[this.requestType](this.api, this.request)
      if (data) {
        this.$emit('item-dispatched')
        this.$toast.success(data.message)
        this.close()
      }
    } catch (err) {
      if (err) {
        this.$emit('error', { errors: err.response.data.errors || {} })
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  close() {
    this.dialog = false
    this.$emit('close')
  }
}
