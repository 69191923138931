
import { Component, Prop, Vue } from 'vue-property-decorator'
import api from '@/plugins/api'
import { mapActions } from 'vuex'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CloseButton from '@/components/buttons/CloseButton.vue'

@Component({
  components: { DeleteButton, SubmitButton, CloseButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class DeleteView extends Vue {
  @Prop() id!: string
  @Prop() activation!: boolean
  dialog = false

  get computeActivation() {
    return !this.activation ? 'Aktivizoni' : 'Çaktivizoni'
  }

  async changeActivation() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().put(`api/users/user-status/${this.id}`)
      if (data) {
        this.$toast.success(data.message)
        this.$emit('activation')
        this.dialog = false
      }
    } catch (err) {
      if (err) await this.$toast.error(err.response.data.message)
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }
}
