
import { Component, Prop, Vue } from 'vue-property-decorator'
import CloseButton from '@/components/buttons/CloseButton.vue'
import jobStatusColor from '@/helpers/jobStatusColor'

export type Operation = {
  job_status: string
  client_name: string
  assigned_date_hour: string
  coordination_id: number
  group_name: string
  activity_name: string
}

@Component({ components: { CloseButton } })
export default class CoordinationCalendar extends Vue {
  dialog = false
  calendar = ''
  type = 'week'
  types = ['month', 'week', 'day']
  @Prop() data!: Operation[]

  prev() {
    ;(this.$refs.calendar as Vue & { prev: () => void }).prev()
  }

  next() {
    ;(this.$refs.calendar as Vue & { next: () => void }).next()
  }

  get events() {
    if (this.data) {
      return this.data.map(
        ({ job_status, client_name, assigned_date_hour, coordination_id, group_name, activity_name }: Operation) => ({
          name: `${client_name} / ${group_name} / ${activity_name}`,
          start: new Date(assigned_date_hour),
          end: this.addHours(new Date(assigned_date_hour)),
          color: jobStatusColor(job_status),
          timed: true,
          coordination_id,
        })
      )
    }
    return []
  }

  addHours(date = new Date()) {
    return date.setTime(date.getTime() + 3600000)
  }

  showOperation({ event }: { event: Record<string, unknown> }) {
    this.$emit('operation-clicked', event.coordination_id)
  }
}
