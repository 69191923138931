export default (jobStatus: string) => {
  switch (jobStatus) {
    case 'done':
      return 'green lighten-2'
    case 'ongoing':
      return 'orange lighten-2'
    case 'todo':
      return 'red lighten-1'
    case 'failed':
      return 'yellow lighten-1'
    default:
      return ''
  }
}
