
import Vue from 'vue'

export default Vue.extend({
  name: 'PartialTimePicker',
  props: {
    time: { type: String, default: '' },
    selectWidth: { type: Number, default: 387 },
    allowTwentyFourHours: { type: Boolean, default: false },
  },
  data: (): {
    defaultZeroValue: '00'
    hours: string[]
    minutes: string[]
    active: boolean
  } => ({
    defaultZeroValue: '00',
    hours: [],
    minutes: [],
    active: false,
  }),
  model: { prop: 'time' },
  computed: {
    computedTime: {
      get(): string {
        return this.time
      },
      set(value: string): void {
        this.$emit('input', value)
      },
    },
    splitValue: {
      get(): string[] {
        return this.computedTime.split(':')
      },
    },
    hourValue: {
      get(): string {
        return this.splitValue[0] || ''
      },
    },
    minuteValue: {
      get(): string {
        return this.splitValue[1] || ''
      },
    },
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hourValue(newValue, _oldValue) {
      if (newValue === '24') {
        this.setMinute('00')
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    minuteValue(_newValue, _oldValue) {
      if (this.hourValue === '24') {
        this.setMinute('00')
      }
    },
  },
  methods: {
    generateNumbStringArray(length: number, step = 1): string[] {
      return this.generateNumberStringSteppedArray(0, length, step)
    },
    generateNumberStringSteppedArray(start: number, stop: number, step: number): string[] {
      return Array.from({ length: (stop - start) / step + 1 }, (_, i) => (start + i * step).toString().padStart(2, '0'))
    },

    valueTemplate(hour: string, minute: string): string {
      return `${hour}:${minute}`
    },
    setHour(value: string): void {
      this.computedTime = this.valueTemplate(value, this.minuteValue || this.defaultZeroValue)
    },
    setMinute(value: string): void {
      this.computedTime = this.valueTemplate(this.hourValue || this.defaultZeroValue, value)
      this.active = false
    },
  },
  mounted() {
    this.hours = this.generateNumbStringArray(this.allowTwentyFourHours ? 24 : 23)
    this.minutes = this.generateNumbStringArray(59)
  },
})
