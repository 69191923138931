import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { AxiosRequestConfig, AxiosInstance } from 'axios'

export default (axiosOptions: AxiosRequestConfig = {}): AxiosInstance => {
  const defaultOptions = {
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: '',
    },
  }

  const accessToken = localStorage.getItem('accessToken')
  const options = { ...defaultOptions, ...axiosOptions }
  if (accessToken) options.headers.Authorization = 'Bearer ' + accessToken

  const axiosInstance = axios.create(options)
  axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      if (error.response.status === 401) {
        return await store
          .dispatch('sendRefreshTokenRequest')
          .then(({ data }) => data)
          .catch(() => {
            const newRequest = error.config
            newRequest.headers.Authorization = `Bearer ${store.getters.accessToken}`
            return axios(newRequest).catch(response => {
              if (response.response.status === 401) {
                store.dispatch('logout')
                if (router.currentRoute.path !== '/login') {
                  return router.push({ name: 'Login' })
                }
              }
              return Promise.reject(response)
            })
          })
      }
      return Promise.reject(error)
    }
  )
  return axiosInstance
}
