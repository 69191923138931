
import { Component, Mixins } from 'vue-property-decorator'
import api from '@/plugins/api'
import Table from '@/mixins/table'
import AssignDevice from '@/views/warehouse/AssignDevice.vue'
import MoveDevicesFromControl from '@/views/move_stock_to_warehouse/MoveDevicesFromControl.vue'

const datatableHeader = [
  { value: 'device_name', text: 'Paisja', sortable: true },
  { value: 'amount', text: 'Sasia', sortable: true },
]

@Component({ components: { MoveDevicesFromControl, AssignDevice } })
export default class MoveStockToWarehouse extends Mixins(Table) {
  headers = datatableHeader

  fetchData() {
    this.loading = true
    const query = this.prepareQuery({})
    api()
      .get(`api/temporary-warehouses${query}`)
      .then(({ data }) => {
        this.data = data.data
        this.totalRecords = data.meta.total
      })
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }
}
