
import { Component, Mixins, Vue } from 'vue-property-decorator'
import api from '@/plugins/api'
import Table from '@/mixins/table'
import Permissions from '@/mixins/Permissions'
import jobStatusColor from '@/helpers/jobStatusColor'
import ShowOperation from '@/views/coordination/ShowOperation.vue'
import { AxiosResponse } from 'axios'

const datatableHeader = [
  { text: 'Grupi/Makina', sortable: false },
  { text: 'Klienti', sortable: false },
]

@Component({ components: { ShowOperation } })
export default class MobileOperationsList extends Mixins(Table, Permissions) {
  headers = datatableHeader
  dialog = false
  item: any = {}
  coordinationId: any = null
  color = jobStatusColor

  async openDialog(id: string) {
    this.coordinationId = id
    await this.$forceUpdate()
    ;((this.$refs.showDialog as Vue).$refs.showDialog as Vue).$data.dialog = true
    await (this.$refs.showDialog as Vue & {
      fetchData: () => Promise<AxiosResponse>
    }).fetchData()
  }

  fetchData() {
    this.loading = true
    const query = this.prepareQuery({})
    api()
      .get(`api/coordination${query}`)
      .then(({ data }) => {
        this.data = data.data
        this.totalRecords = data.meta.total
      })
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }
}
