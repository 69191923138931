
import { Component, Model, Prop, Vue } from 'vue-property-decorator'
export type DevicePrice = { name: string; price: string }
@Component({})
export default class PricePerDeviceTable extends Vue {
  @Prop({ type: Boolean, default: false }) readonly!: boolean
  @Prop() @Model('input') value!: Array<DevicePrice>
  @Prop({ default: () => ({}), type: Object }) errors!: Record<string, string[]>
  get syncValue() {
    return this.value
  }

  set syncValue(value) {
    this.$emit('input', value)
  }

  clearFieldError(key: string) {
    this.$emit('clear-field-error', key)
  }
}
