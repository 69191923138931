
import { Component } from 'vue-property-decorator'
import api from '@/plugins/api'
import Form from '@/mixins/form'
import { mixins } from 'vue-class-component'
import { mapActions } from 'vuex'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import AssignButton from '@/components/buttons/AssignButton.vue'
import DropdownData from '@/services/DropdownData'

@Component({
  components: { AssignButton, SubmitButton, CloseButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class AssignDevice extends mixins(Form, DropdownData) {
  dialog = false
  warehouseAmount = null
  request: any = {
    group_id: null,
    device_id: null,
    status_id: null,
    amount: null,
  }

  fetchData() {
    this.fetchGroups()
    this.fetchDevices()
    this.fetchDevicesAssignableStatuses()
  }
  fetchWarehouseDeviceAmount() {
    if (this.request.device_id && this.request.status_id) {
      api()
        .get(`api/warehouses/${this.request.device_id}/${this.request.status_id}?no-pagination=true`)
        .then(({ data }) => (this.warehouseAmount = data.data.amount))
    }
  }

  async assign() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().post(`api/warehouses/assign`, this.request)
      if (data) {
        this.$toast.success(data.message)
        this.$emit('device-assigned')
        this.close()
      }
    } catch (err) {
      if (err) {
        this.errors = err.response.data.errors || {}
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  close() {
    this.dialog = false
    this.request = {
      group_id: null,
      device_id: null,
      status_id: null,
      amount: null,
    }
    this.warehouseAmount = null
    this.clearErrors()
  }
}
