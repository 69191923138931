import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class Form extends Vue {
  errors: Record<string, Array<string>> = {}

  clearFieldError(key: string) {
    Vue.delete(this.errors, key)
  }

  clearErrors() {
    this.errors = {}
  }

  clearSpecificErrors(attribute: any) {
    Object.keys(this.errors).forEach((key: string) => {
      if (key.includes(attribute)) this.clearFieldError(key)
    })
  }

  passErrors({ errors }: any) {
    this.errors = errors
  }

  tagFieldErrors(errors: Record<string, Array<string>>, key: string) {
    const errorKeys = Object.getOwnPropertyNames(errors)
    return errorKeys.filter((item: string) => item.includes(key)).map((item: string) => +item.split('.')[1])
  }

  colorPerTagError(errorContainer: number[], index: number, color: string) {
    return errorContainer.includes(index) ? color : ''
  }
}
