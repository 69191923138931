
import { Component, Mixins } from 'vue-property-decorator'
import api from '@/plugins/api'
import Table from '@/mixins/table'
import AuditTrailSearch from '@/views/audit_trail/partials/AuditTrailSearch.vue'
import ShowAuditTrail from '@/views/audit_trail/ShowAuditTrail.vue'

const datatableHeader = [
  { value: 'activity_log_id', text: 'Id veprimit', sortable: true },
  { value: 'user_name', text: 'Emri Përdoruesit', sortable: true },
  { value: 'group_name', text: 'Grupi i Përdoruesit', sortable: true },
  { value: 'model_name', text: 'Modeli', sortable: true },
  { value: 'activity_date', text: 'Data e Veprimit', sortable: true },
  { value: 'coordination_activity_name', text: 'Veprimi', sortable: true },
  { value: 'actions', text: 'Detaje', sortable: false },
]

@Component({ components: { ShowAuditTrail, AuditTrailSearch } })
export default class AuditTrail extends Mixins(Table) {
  headers = datatableHeader
  request = {
    user_name: null,
    coordination_activity_name: null,
    group_id: null,
    activity_date: null,
  }
  fetchData() {
    this.loading = true
    const query = this.prepareQuery({})
    api()
      .get(`api/activities${query}`, { params: this.request })
      .then(({ data }) => {
        this.data = data.data
        this.totalRecords = data.meta.total
      })
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }
}
