
import { Component, Prop } from 'vue-property-decorator'
import Form from '@/mixins/form'
import { mixins } from 'vue-class-component'
import EditDialogView from '@/views/core/dialog_view/EditDialogView.vue'

@Component({ components: { EditDialogView } })
export default class EditStatus extends mixins(Form) {
  @Prop() id!: number | string
  @Prop() statusName!: string
  request: { name: string | null; id: string | number | null } = {
    name: null,
    id: null,
  }

  fetchProps() {
    this.request.name = this.statusName
    this.request.id = this.id
  }
}
