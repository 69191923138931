
import { Component, Mixins } from 'vue-property-decorator'
import api from '@/plugins/api'
import { mapActions } from 'vuex'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import DeleteRole from '@/views/roles/DeleteRole.vue'
import AddRole from '@/views/roles/AddRole.vue'
import Form from '@/mixins/form'

@Component({
  components: { AddRole, DeleteRole, SubmitButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class Roles extends Mixins(Form) {
  adminRoleId = 1
  allPermissions: Array<any> = []
  roles: Array<any> = []
  role: any = null
  request: { name: string | null; permissions: Array<any> } = {
    name: null,
    permissions: [],
  }

  setRole(value: any) {
    if (this.role && value.id === this.role.id) {
      this.unsetAllPermissions()
      this.request.name = null
      this.request.permissions = []
      return (this.role = null)
    }
    this.role = value
    this.request.permissions = []
    this.request.name = this.role.name
    this.unsetAllPermissions()
    this.allPermissions.forEach((permission: any) => {
      this.role.permissions.forEach((initialPermissions: any) => {
        if (permission.id === initialPermissions.id) {
          this.request.permissions.push(permission)
          permission.checked = true
        }
      })
    })
  }

  prepareRolePermissions(permission: any) {
    this.request.permissions.forEach((reqPermission: any, index: number) => {
      if (reqPermission.id === permission.id) this.request.permissions.splice(index, 1)
    })
    if (permission.checked) this.request.permissions.push(permission)
  }

  beforeMount() {
    api()
      .get(`api/permissions?no-pagination=true`)
      .then(({ data }) => (this.allPermissions = data.data))
    this.fetchRoles()
  }

  fetchRoles() {
    api()
      .get(`api/roles`)
      .then(({ data }) => (this.roles = data.data))
  }

  async saveChanges() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().put(`api/roles/${this.role.id}`, this.request)
      if (data) {
        this.fetchRoles()
        this.$toast.success(data.message)
      }
    } catch (err) {
      if (err) {
        this.$emit('error', { errors: err.response.data.errors || {} })
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  clearForm() {
    this.role = null
    this.request = { name: null, permissions: [] }
    this.unsetAllPermissions()
    this.fetchRoles()
  }

  unsetAllPermissions() {
    this.allPermissions.forEach((permission: any) => (permission.checked = false))
  }
}
