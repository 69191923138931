
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { mapActions } from 'vuex'
import api from '@/plugins/api'
import Form from '@/mixins/form'
import AddButton from '@/components/buttons/AddButton.vue'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import DropdownData from '@/services/DropdownData'

@Component({
  components: { SubmitButton, CloseButton, AddButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class CreateUser extends mixins(Form, DropdownData) {
  dialog = false
  request = {
    name: null,
    surname: null,
    email: null,
    roles: null,
    password: null,
    password_confirmation: null,
    technician_id: null,
  }

  fetchData() {
    this.fetchRoles()
    this.fetchTechnicians()
  }

  async addUser() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().post('api/users', this.request)
      this.$emit('user-created')
      this.close()
      this.$toast.success(data.message)
    } catch (err) {
      if (err) {
        this.errors = err.response.data.errors || {}
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  close() {
    this.dialog = false
    this.request = {
      name: null,
      surname: null,
      email: null,
      roles: null,
      password: null,
      password_confirmation: null,
      technician_id: null,
    }
    this.clearErrors()
  }
}
