
import { Vue, Prop, Component, Model } from 'vue-property-decorator'
import api from '@/plugins/api'

type T = number | string | { name: string; id: string | number }[]

@Component({})
export default class DeviceSelect extends Vue {
  items = []
  loading = false
  @Prop() @Model('input') value!: T

  get syncValue(): T {
    return this.value
  }
  set syncValue(value) {
    this.$emit('input', value)
  }

  async mounted() {
    await this.fetchData()
  }
  async fetchData() {
    this.loading = true
    try {
      const { data } = await api().get('/api/devices?no-pagination=true')
      if (data) this.items = data.data
    } finally {
      this.loading = false
    }
  }
}
