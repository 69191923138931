
import { Component, Mixins, Prop } from 'vue-property-decorator'
import Permissions from '@/mixins/Permissions'
import api from '@/plugins/api'
import { mapActions } from 'vuex'
import Form from '@/mixins/form'

@Component({
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class ItCheckBox extends Mixins(Permissions, Form) {
  @Prop() value!: number | boolean
  @Prop({ default: NaN, type: Number, required: true }) id!: number

  async changeItStatus() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().put(`api/it-checkout/${this.id}`)
      this.$toast.success(data.message)
      this.$emit('checkout')
    } catch (err) {
      if (err) this.$toast.error(err.response.data.message)
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }
}
