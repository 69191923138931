
import { Component, Mixins } from 'vue-property-decorator'
import MenuNode from '@/modules/main_menu/MenuNode.vue'
import UserDropdown from '@/modules/main_menu/UserDropdown.vue'
import MenuItems from '@/modules/main_menu/partials/MenuItems'
import TableOfContentCard from '@/modules/main_menu/TableOfContentCard.vue'
import api from '@/plugins/api'

@Component({ components: { TableOfContentCard, UserDropdown, MenuNode } })
export default class Dashboard extends Mixins(MenuItems) {
  drawer = true
  beforeMount() {
    api().get('api/cities')
    addEventListener('visibilitychange', () => {
      if (!document.hidden) api().get('api/cities')
    })
  }

  routerPush(path: string) {
    if (this.$route.path !== path) this.$router.push(path)
  }
}
