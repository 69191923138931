
import { Component, Mixins } from 'vue-property-decorator'
import api from '@/plugins/api'
import Table from '@/mixins/table'
import MoveDevicesFromGroup from '@/views/move_stock_to_control/MoveDevicesFromGroup.vue'
import DeviceTransfer from '@/views/move_stock_to_control/DeviceTransfer.vue'
import Permissions from '@/mixins/Permissions'
import SendInventoryEmail from '@/components/buttons/SendInventoryEmail.vue'

const datatableHeader = [
  { value: 'group_name', text: 'Grupi', sortable: true },
  { value: 'total_new', text: 'Të Reja', sortable: false },
  { value: 'total_used', text: 'Të Përdorura', sortable: false },
  { value: 'total_return', text: 'Për Kontroll', sortable: false },
  { value: 'total', text: 'Total', sortable: false },
]

@Component({ components: { SendInventoryEmail, DeviceTransfer, MoveDevicesFromGroup } })
export default class MoveStockToControl extends Mixins(Table, Permissions) {
  headers = datatableHeader
  loadingGroup = false
  groupData = []
  groupName = ''
  groupId = ''

  fetchData() {
    this.loading = true
    const query = this.prepareQuery({})
    api()
      .get(`api/group-inventories${query}`)
      .then(({ data }) => {
        this.data = data.data
        this.totalRecords = data.meta.total
      })
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }

  fetchGroupData({ group_id, group_name }: { group_id: string; group_name: string }) {
    this.groupId = group_id
    this.groupName = group_name
    this.loadingGroup = true
    api()
      .get(`api/group-inventories/${group_id}`)
      .then(({ data }) => (this.groupData = data.data))
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loadingGroup = false))
  }
  refreshData() {
    this.fetchData()
    this.groupData = []
    this.groupName = ''
  }
  devicesMoved() {
    this.fetchData()
    this.fetchGroupData({ group_id: this.groupId, group_name: this.groupName })
  }

  itemClass({ group_id }: { group_id: string }) {
    return (group_id === this.groupId ? 'grey lighten-3 ' : '') + 'cursor-pointer'
  }

  isAdminOrWarehouseman() {
    return this.$store.getters.roles.includes('warehouseman') || this.$store.getters.roles.includes('admin')
  }
}
