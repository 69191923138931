
import { Component, Mixins, Vue } from 'vue-property-decorator'
import api from '@/plugins/api'
import Table from '@/mixins/table'
import ShowOperation from '@/views/coordination/ShowOperation.vue'
import EditOperation from '@/views/coordination/EditOperation.vue'
import DeleteView from '@/views/core/dialog_view/DeleteView.vue'
import CoordinationSearch from '@/views/coordination/paritals/CoordinationSearch.vue'
import Permissions from '@/mixins/Permissions'
import LocationMarker from '@/components/LocationMarker.vue'
import CreateOperation from '@/views/coordination/CreateOperation.vue'
import { AxiosResponse } from 'axios'
import jobStatusColor from '@/helpers/jobStatusColor'
import InvoiceCheckbox from '@/views/coordination/paritals/InvoiceCheckbox.vue'
import CoordinationCalendar from '@/views/coordination/paritals/CoordinationCalendar.vue'
import { mapGetters } from 'vuex'
import { DataTableHeader } from 'vuetify'

@Component({
  components: {
    CoordinationCalendar,
    InvoiceCheckbox,
    CreateOperation,
    LocationMarker,
    CoordinationSearch,
    DeleteView,
    EditOperation,
    ShowOperation,
  },
  computed: { ...mapGetters(['isMapEnabled']) },
})
export default class Coordination extends Mixins(Table, Permissions) {
  dialog = false
  clickedMarkerId: any = null
  request = {
    coordination_activity_id: null,
    groups: [],
    client_name: null,
    activity_date_from: null,
    activity_date_end: null,
    created_at_from: null,
    created_at_end: null,
    job_status: [],
    coordination_id: null,
    is_invoiced: null,
    city_id: null,
    plate_name: null,
    invoiced: null,
  }
  locations = []

  fetchData() {
    this.loading = true
    const query = this.prepareQuery({})
    api()
      .get(`api/coordination${query}`, { params: this.request })
      .then(({ data }) => {
        this.data = data.data
        this.totalRecords = data.meta.total
      })
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))

    api()
      .get('api/coordination-locations?no-pagination=true')
      .then(({ data }) => (this.locations = data.data))
  }
  openCreateDialog({ lat, lng }: any) {
    if (this.hasPermission('add_coordination')) {
      ;(this.$refs.coordinationDialog as Vue).$data.dialog = true
      ;(this.$refs.coordinationDialog as Vue).$data.request.latitude = lat
      ;(this.$refs.coordinationDialog as Vue).$data.request.longitude = lng
      ;(this.$refs.coordinationDialog as Vue & {
        fetchData: () => Promise<AxiosResponse>
      }).fetchData()
    } else {
      this.locations.splice(-1)
      alert('You have no permission !')
    }
  }

  async showOperation(id: number) {
    this.clickedMarkerId = id
    await this.$forceUpdate()
    ;((this.$refs.markerShowDialog as Vue).$refs.showDialog as Vue).$data.dialog = true
    await (this.$refs.markerShowDialog as Vue & {
      fetchData: () => Promise<AxiosResponse>
    }).fetchData()
    this.clickedMarkerId = null
  }

  async showCalendar() {
    ;(this.$refs.coordinationCalendar as Vue).$data.dialog = true
  }

  updateNotes(notes: string, index: number) {
    this.data[index].notes = notes
  }

  indexOf(id: string | number) {
    return this.data.map((x: { coordination_id: string | number }) => x.coordination_id).indexOf(id)
  }

  color({ job_status }: { job_status: string }) {
    return jobStatusColor(job_status)
  }

  get headers() {
    const [
      group,
      priority,
      client,
      newPlate,
      oldPlate,
      activity,
      device,
      city,
      createdAt,
      assignedDateHour,
      activityDate,
      actions,
    ]: Array<DataTableHeader> = [
      { value: 'group_name', text: 'Grupi/Makina', sortable: true },
      { value: 'priority', text: 'Prioriteti', sortable: true },
      { value: 'client_name', text: 'Klienti', sortable: true },
      { value: 'new_plate', text: 'Targa Re', sortable: true },
      { value: 'old_plate', text: 'Targa Vjetër', sortable: true },
      { value: 'activity_name', text: 'Veprimi', sortable: true },
      { value: 'device_name', text: 'Paisja', sortable: true },
      { value: 'city_name', text: 'Qyteti', sortable: false },
      { value: 'created_at', text: 'Krijuar', sortable: true },
      { value: 'assigned_date_hour', text: 'Caktuar', sortable: true },
      { value: 'activity_date', text: 'Mbaruar', sortable: true },
      { value: 'actions', sortable: false, text: '' },
    ]
    const price: DataTableHeader = { value: 'price', sortable: true, text: 'Cmimi' }

    const allHeaders: Array<DataTableHeader> = [
      group,
      priority,
      client,
      newPlate,
      oldPlate,
      activity,
      device,
      city,
      createdAt,
      assignedDateHour,
      activityDate,
    ]

    const headersOngoingJobs: Array<DataTableHeader> = [
      group,
      priority,
      client,
      activity,
      city,
      createdAt,
      assignedDateHour,
    ]
    const headersDoneJobs: Array<DataTableHeader> = [
      group,
      client,
      newPlate,
      oldPlate,
      activity,
      device,
      city,
      createdAt,
      assignedDateHour,
      activityDate,
    ]

    const withPriceAndActionsHeaders: (headers: Array<DataTableHeader>) => Array<DataTableHeader> = headers =>
      this.isFinanceOrAdminRole ? [...headers, price, actions] : [...headers, actions]

    const jobStatus = this.request.job_status as Array<string>

    return withPriceAndActionsHeaders(
      jobStatus.includes('ongoing') && this.request.job_status.length === 1
        ? headersOngoingJobs
        : jobStatus.includes('done') && this.request.job_status.length === 1
        ? headersDoneJobs
        : allHeaders
    )
  }

  toUpperCase(string: string | undefined): string {
    return typeof string === 'string' ? string.toUpperCase() : ''
  }

  cloneWork(itemToBeClonedRequest: Record<string, unknown>) {
    if (this.hasPermission('add_coordination')) {
      ;(this.$refs.coordinationDialog as Vue).$data.dialog = true
      ;(this.$refs.coordinationDialog as Vue).$data.request = itemToBeClonedRequest
      ;(this.$refs.coordinationDialog as Vue & {
        fetchData: () => Promise<AxiosResponse>
      }).fetchData()
    } else alert('You have no permission !')
  }

  get tableWidth() {
    //@ts-ignore
    return this.isMapEnabled ? '10' : '12'
  }

  get isFinanceOrAdminRole() {
    return this.$store.getters.roles.includes('finance') || this.$store.getters.roles.includes('admin')
  }

  assignationDismissed() {
    //@ts-ignore
    if (this.isMapEnabled) this.locations.splice(-1)
  }
}
