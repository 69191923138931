
import { Component, Vue } from 'vue-property-decorator'
import api from '@/plugins/api'

@Component({})
export default class RecentlyClosedJobs extends Vue {
  loading = false
  data: any[] = []

  beforeMount() {
    this.loading = true
    api()
      .get('api/daily-jobs')
      .then(({ data }) => (this.data = data.data))
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }
}
