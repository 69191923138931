
import { Prop, Component, Vue, Model } from 'vue-property-decorator'

@Component({})
export default class DatePicker extends Vue {
  @Prop() @Model('input') value!: string
  @Prop() label!: string
  @Prop({ default: false, type: Boolean }) outlined!: boolean
  @Prop() errorMessage!: string[]
  @Prop({ default: false, type: Boolean }) disabled!: boolean
  @Prop() hint!: string
  @Prop({ default: false, type: Boolean }) dense!: boolean
  @Prop() max!: string
  @Prop({ default: '' }) textFieldClass!: string
  picker = false

  get syncDate(): string {
    return this.value
  }

  set syncDate(value) {
    this.$emit('input', value)
    this.$emit('date-changed')
  }

  switchPicker() {
    this.picker = !this.picker
  }
}
