
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import isMobile from '@/router/permissions/isMobile'
import Permissions from '@/mixins/Permissions'
@Component({
  methods: { ...mapActions(['sendLogoutRequest']) },
  computed: { ...mapGetters(['fullName']) },
})
export default class UserDropdown extends Mixins(Permissions) {
  @Prop({ default: 50 }) iconSize!: string | number
  logout() {
    //@ts-ignore
    this.sendLogoutRequest().then(() => {
      this.$router.push({ name: 'Login' })
    })
  }

  routerPush() {
    if (!isMobile()) return this.changeRoute('/change-password')
    if (isMobile()) return this.changeRoute('/mobile-change-password')
    alert('Something went wrong! You are not allowed to go to that Route!')
  }

  changeRoute(path: string) {
    if (this.$route.path !== path) this.$router.push(path)
  }

  get isMobile() {
    return isMobile()
  }
}
