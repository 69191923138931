
import { Component, Mixins } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import Permissions from '@/mixins/Permissions'

@Component({
  methods: { ...mapActions(['disableFullSearchCoordination', 'enableFullSearchCoordination']) },
  computed: { ...mapGetters(['isFullSearchCoordinationEnabled']) },
})
export default class CoordinationSearchSwitch extends Mixins(Permissions) {
  get syncIsCoordinationFullSearchEnabled() {
    //@ts-ignore
    return this.isFullSearchCoordinationEnabled
  }
  set syncIsCoordinationFullSearchEnabled(_) {
    //@ts-ignore
    this.isFullSearchCoordinationEnabled ? this.disableFullSearchCoordination() : this.enableFullSearchCoordination()
  }
}
