
import { Vue, Prop, Component, Model } from 'vue-property-decorator'
import api from '@/plugins/api'

type CompanyApplication = number | string | { name: string; id: string | number }[]

@Component({})
export default class CompanyApplicationSelect extends Vue {
  items = []
  loading = false
  @Prop() @Model('input') companyApplication!: CompanyApplication

  get syncCompanyApplication(): CompanyApplication {
    return this.companyApplication
  }
  set syncCompanyApplication(value) {
    this.$emit('input', value)
  }

  async mounted() {
    this.loading = true
    try {
      const { data } = await api().get('api/gps-gate/applications?no-pagination=true')
      if (data) this.items = data.data
    } finally {
      this.loading = false
    }
  }
}
