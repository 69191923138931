
import { Component, Mixins, Prop } from 'vue-property-decorator'
import Permissions from '@/mixins/Permissions'
import api from '@/plugins/api'
import { mapActions } from 'vuex'
import Form from '@/mixins/form'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

@Component({
  components: { SubmitButton, CloseButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class ItMarkLocation extends Mixins(Permissions, Form) {
  dialog = false
  request = { latitude: '', longitude: '' }
  @Prop({ default: NaN, type: Number, required: true }) id!: number

  fetchData() {
    api()
      .get(`api/it-mark-location/${this.id}`)
      .then(({ data }) => (this.request = data.data))
  }

  async markLocation() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().put(`api/it-mark-location/${this.id}`, this.request)
      if (data) {
        this.$emit('marked')
        this.close()
        this.$toast.success(data.message)
      }
    } catch (err) {
      if (err) {
        this.errors = err.response.data.errors || {}
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  close() {
    this.dialog = false
    this.request.latitude = ''
    this.request.longitude = ''
    this.clearErrors()
  }
}
