
import { Component, Prop, Vue } from 'vue-property-decorator'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import api from '@/plugins/api'
import { mapActions } from 'vuex'

@Component({
  components: { EditButton, SubmitButton, CloseButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class EditDialogView extends Vue {
  @Prop() title!: string
  @Prop() width!: string | number
  @Prop() submitButtonLabel!: string
  @Prop({ default: '', type: String }) closeButtonLabel!: string
  @Prop() api!: string
  @Prop() request!: any
  dialog = false

  async editItem() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().put(this.api, this.request)
      if (data) {
        this.close()
        this.$emit('item-edited')
        this.$toast.success(data.message)
      }
    } catch (err) {
      if (err) {
        this.$emit('error', { errors: err.response.data.errors || {} })
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  close() {
    this.dialog = false
    this.$emit('close')
  }
}
