
import { Component, Prop, Vue } from 'vue-property-decorator'
import api from '@/plugins/api'
import ShowDialogView from '@/views/core/dialog_view/ShowDialogView.vue'
import DetailsItem from '@/components/details_items/DetailsItem.vue'
import DescriptionDetailsItem from '@/components/details_items/DescriptionDetailsItem.vue'
import { src64 } from '@/utils/base64ToFile'
import moment from 'moment'

@Component({ components: { DescriptionDetailsItem, DetailsItem, ShowDialogView } })
export default class ShowOperation extends Vue {
  @Prop() id!: number | string
  @Prop() fullscreen!: boolean
  @Prop({ default: true, type: Boolean }) showEyeIcon!: boolean
  item: any = {}
  src64 = src64
  fetchData() {
    api()
      .get(`api/coordination/${this.id}`)
      .then(async ({ data }) => {
        this.item = data.data
        const images: { image: string }[] = data.data.images
        this.item.images = []
        this.$forceUpdate()
        this.item.images = images.map(({ image }) => image)
      })
  }

  formattedDate(date: string) {
    return moment(date).format('DD/mm/YYYY hh:mm:ss')
  }
  async openImg(base64: string) {
    const image = new Image()
    image.src = this.src64(base64)
    const w = await window.open('')
    if (w) w.document.write(image.outerHTML)
  }
}
