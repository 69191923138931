
import { Component, Prop, Vue } from 'vue-property-decorator'
import api from '@/plugins/api'
import ShowDialogView from '@/views/core/dialog_view/ShowDialogView.vue'
import DetailsItem from '@/components/details_items/DetailsItem.vue'
import LogDetailsItem from '@/components/details_items/LogDetailsItem.vue'

@Component({ components: { LogDetailsItem, DetailsItem, ShowDialogView } })
export default class ShowTechnician extends Vue {
  @Prop() id!: number | string
  item = {}

  fetchData() {
    api()
      .get(`api/technicians/${this.id}`)
      .then(({ data }) => (this.item = data.data))
  }
}
