import { Vue, Component, Watch } from 'vue-property-decorator'
import { Debounce } from 'vue-debounce-decorator'

@Component({})
export default class Table extends Vue {
  data: any = []
  items: any = []
  totalRecords = 0
  loading = true
  pagination: any = {}
  search = ''
  query = ``

  public get pages() {
    if (!this.pagination.itemsPerPage || !this.totalRecords) return 0

    return Math.ceil(this.totalRecords / this.pagination.itemsPerPage)
  }

  @Watch('pagination', { deep: true })
  onPaginationChange() {
    // @ts-ignore
    this.fetchData()
  }

  @Watch('search', { deep: true })
  @Debounce(500)
  onSearchChange() {
    //@ts-ignore
    if (this.pagination.page === 1) this.fetchData()
    this.pagination.page = 1
  }

  prepareQuery(params?: any) {
    const { page, sortBy, sortDesc, itemsPerPage } = this.pagination
    let extraParams = ''
    for (const paramName in params) {
      extraParams += `&${paramName}=${params[paramName]}`
    }
    if (itemsPerPage === -1) {
      this.query = `?no-pagination=true&page=${page}&sort-by=${sortBy}&descending=${sortDesc[0]}`
    } else {
      this.query = `?page-size=${itemsPerPage}&page=${page}&sort-by=${sortBy}&descending=${sortDesc[0]}`
    }

    if (this.search) {
      extraParams += `&qs=${this.search}`
    }
    return `${this.query}${extraParams}`
  }
}
