
import { Component, Mixins } from 'vue-property-decorator'
import Form from '@/mixins/form'
import api from '@/plugins/api'
import { mapActions } from 'vuex'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import Permissions from '@/mixins/Permissions'

@Component({
  components: { SubmitButton, CloseButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class ChangeAuthPassword extends Mixins(Form, Permissions) {
  request = {
    current_password: null,
    new_password: null,
    new_password_confirmation: null,
  }

  async changePassword() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().put('api/update-password', this.request)
      if (data) {
        this.routerPush()
        this.$toast.success(data.message)
      }
    } catch (err) {
      if (err.response) {
        this.errors = err.response.data.errors || {}
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  routerPush() {
    this.hasPermission('dashboard') && this.$route.path === '/change-password'
      ? this.$router.push({ name: 'Dashboard' })
      : this.hasPermission('mobile_dashboard') && this.$route.path === '/mobile-change-password'
      ? this.$router.push({ name: 'MobileDashboard' })
      : null
  }
}
