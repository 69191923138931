
import { Component, Mixins } from 'vue-property-decorator'
import api from '@/plugins/api'
import Table from '@/mixins/table'
import Permissions from '@/mixins/Permissions'
import ClientForm from '@/views/clients/ClientForm.vue'
import DeleteView from '@/views/core/dialog_view/DeleteView.vue'

const datatableHeader = [
  { value: 'id', text: 'Id', sortable: true },
  { value: 'name', text: 'Emri', sortable: true },
  { value: 'actions', text: 'Veprime', sortable: false },
]

@Component({ components: { ClientForm, DeleteView } })
export default class ClientsList extends Mixins(Table, Permissions) {
  headers = datatableHeader
  fetchData() {
    this.loading = true
    const query = this.prepareQuery({})
    api()
      .get(`api/companies${query}`)
      .then(({ data }) => {
        this.data = data.data
        this.totalRecords = data.meta.total
      })
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }
}
