
import { Vue, Prop, Component } from 'vue-property-decorator'
import api from '@/plugins/api'

@Component({})
export default class SendInventoryEmail extends Vue {
  @Prop() label!: string
  @Prop() on!: any
  @Prop() attrs!: any
  request = []

  async sendEmail() {
    try {
      const { data } = await api().post('api/send-inventory-email', this.request)
      if (data) this.$toast.success(data.message)
    } catch (err) {
      if (err.response) this.$toast.error(err.response.data.message)
    }
  }
}
