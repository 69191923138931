
import Leaflet from 'easy-vue-leaflet'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({ components: { Leaflet } })
export default class LocationMarker extends Vue {
  @Prop() value!: Array<any>
  @Prop() isMobile!: boolean
  options = { view: { lat: 41.1533, lng: 20.1683, zoom: 8 } }
  // todo circles
  circles = [
    //this is an example how a circle should be
    // {
    //   position: { lat: 41.1533, lng: 20.1683 },
    //   radius: 100000,
    //   color: 'red',
    // },
  ]
  markLocation({ position }: any) {
    this.value.push({ position })
    this.$emit('location-marked', position)
  }

  markerClicked({ marker }: any) {
    this.$emit('marker-clicked', marker.id)
  }
}
