
import { Component, Prop } from 'vue-property-decorator'
import api from '@/plugins/api'
import Form from '@/mixins/form'
import { mixins } from 'vue-class-component'
import { mapActions } from 'vuex'
import CloseButton from '@/components/buttons/CloseButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import Permissions from '@/mixins/Permissions'

@Component({
  components: { SubmitButton, CloseButton },
  methods: { ...mapActions(['hideOverlay', 'showOverlay']) },
})
export default class MoveDevicesFromGroup extends mixins(Form, Permissions) {
  @Prop() device!: any
  @Prop() groupId!: any
  dialog = false
  request = { amount: null, device_id: null, status_id: null }

  prepareRequest() {
    this.request.device_id = this.device.device_id
    this.request.status_id = this.device.status_id
  }

  async moveFromGroup() {
    //@ts-ignore
    this.showOverlay()
    try {
      const { data } = await api().post(`api/group-inventories/${this.groupId}/return`, this.request)
      if (data) {
        this.$emit('devices-moved')
        this.close()
        this.$toast.success(data.message)
      }
    } catch (err) {
      if (err) {
        this.errors = err.response.data.errors || {}
        await this.$toast.error(err.response.data.message)
      }
    } finally {
      //@ts-ignore
      this.hideOverlay()
    }
  }

  close() {
    this.dialog = false
    this.request = { amount: null, device_id: null, status_id: null }
    this.clearErrors()
  }
}
