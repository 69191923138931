
import { Component, Prop } from 'vue-property-decorator'
import api from '@/plugins/api'
import Form from '@/mixins/form'
import { mixins } from 'vue-class-component'
import EditDialogView from '@/views/core/dialog_view/EditDialogView.vue'
import DropdownData from '@/services/DropdownData'

@Component({ components: { EditDialogView } })
export default class EditGroup extends mixins(Form, DropdownData) {
  @Prop() id!: number | string
  request = {}

  fetchData() {
    this.fetchTechnicians()
    api()
      .get(`api/groups/${this.id}`)
      .then(({ data }) => {
        this.request = {
          technicians: data.data.technicians.map(({ technician_id }: { technician_id: number }) => technician_id),
          name: data.data.name,
        }
      })
  }
}
