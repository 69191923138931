
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import Table from '@/mixins/table'
import api from '@/plugins/api'
import Permissions from '@/mixins/Permissions'
import ShowOperation from '@/views/coordination/ShowOperation.vue'
import { AxiosResponse } from 'axios'
import jobStatusColor from '@/helpers/jobStatusColor'
import { Debounce } from 'vue-debounce-decorator'
import { JOB_STATUSES } from '@/constants/jobStatuses'
const datatableHeader = [
  { value: 'group_name', text: 'Grupi', sortable: false },
  { value: 'created_at', text: 'Krijuar', sortable: false },
  { value: 'assigned_date_hour', text: 'Caktuar', sortable: false },
  { value: 'activity_date', text: 'Mbaruar', sortable: false },
]
@Component({ components: { ShowOperation } })
export default class JobsPerPlate extends Mixins(Table, Permissions) {
  headers = datatableHeader
  request = { plate_name: null, job_status: [JOB_STATUSES.done] }
  clickedRowId: string | number | null = null

  @Watch('request.plate_name', { deep: true })
  @Debounce(500)
  onRequestChange() {
    //@ts-ignore
    if (this.pagination.page === 1) this.fetchData()
    this.pagination.page = 1
  }

  fetchData() {
    this.loading = true
    const query = this.prepareQuery({})
    api()
      .get(`api/coordination${query}`, { params: this.request })
      .then(({ data }) => {
        this.data = data.data
        this.totalRecords = data.meta.total
      })
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }

  async openJobModal({ coordination_id }: { coordination_id: number }) {
    this.clickedRowId = coordination_id
    await this.$forceUpdate()
    ;((this.$refs.showDialog as Vue).$refs.showDialog as Vue).$data.dialog = true
    await (this.$refs.showDialog as Vue & {
      fetchData: () => Promise<AxiosResponse>
    }).fetchData()
    this.clickedRowId = null
  }

  color({ job_status }: { job_status: string }) {
    return jobStatusColor(job_status)
  }
}
