
import { Component, Mixins } from 'vue-property-decorator'
import api from '@/plugins/api'
import Table from '@/mixins/table'
import ShowUser from '@/views/users/ShowUser.vue'
import EditUser from '@/views/users/EditUser.vue'
import ChangeUserPassword from '@/views/users/ChangeUserPassword.vue'
import CreateUser from '@/views/users/CreateUser.vue'
import ActivationUser from '@/views/users/ActivationUser.vue'

const datatableHeader = [
  { value: 'name', text: 'Emri', sortable: true },
  { value: 'surname', text: 'Mbiemri', sortable: true },
  { value: 'actions', text: 'Veprime', sortable: false },
]

@Component({
  components: { ActivationUser, CreateUser, ChangeUserPassword, EditUser, ShowUser },
})
export default class UsersList extends Mixins(Table) {
  headers = datatableHeader
  fetchData() {
    this.loading = true
    const query = this.prepareQuery({})
    api()
      .get(`api/users${query}`)
      .then(({ data }) => {
        this.data = data.data
        this.totalRecords = data.meta.total
      })
      .catch(({ response }) => this.$toast.error(response.data.message))
      .finally(() => (this.loading = false))
  }
}
