
import { Component } from 'vue-property-decorator'
import Form from '@/mixins/form'
import { mixins } from 'vue-class-component'
import AddDialogView from '@/views/core/dialog_view/AddDialogView.vue'
import api from '@/plugins/api'

@Component({ components: { AddDialogView } })
export default class AddRole extends mixins(Form) {
  allPermissions: Array<any> = []
  request: { name: string | null; permissions: Array<any> } = {
    name: null,
    permissions: [],
  }

  beforeMount() {
    api()
      .get(`api/permissions?no-pagination=true`)
      .then(({ data }) => (this.allPermissions = data.data))
  }

  prepareRolePermissions(permission: any) {
    this.clearFieldError('permissions')
    this.request.permissions.forEach((reqPermission: any, index: number) => {
      if (reqPermission.id === permission.id) this.request.permissions.splice(index, 1)
    })
    if (permission.checked) this.request.permissions.push(permission)
  }

  close() {
    this.request = { name: null, permissions: [] }
    this.allPermissions.forEach((permission: any) => (permission.checked = false))
    this.clearErrors()
  }
}
